/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel3';
import { useSelector, useDispatch } from 'react-redux';
import CarouselItem from './common/CarouselItem';
import SectionHeading from './common/SectionHeading';
import FontAwesome from './common/FontAwesome';
import settings from '../config/settings';
import { setSpecials, clearSpecials } from '../redux/reducers/specialSlice';

const Index = () => {
  const dispatch = useDispatch();
  const specials = useSelector((state) => state.special.specials);

  useEffect(() => {
    // side effects
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch(`${settings.API_URL}/public/items/getSpecials`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Unauthorized');
      })
      .then((data) => {
        dispatch(setSpecials(data.specials));
      })
      .catch((error) => {
        console.log(error);
      });

    // cleanup
    return () => {
      dispatch(clearSpecials());
    };
  }, []);

  return (
    <>
      <h1 style={{ textAlign: 'center', marginTop: '30px' }}>
        Welcome to
        {' '}
        {settings.restaurantName}
      </h1>
      {specials.length > 0 ? (
        <section className="section pt-5 pb-5 products-section">
          <Container>
            <SectionHeading
              heading="Specials"
              subHeading={`${settings.restaurantName} Specials`}
            />
            <Row>
              <Col md={12}>
                <OwlCarousel
                // center
                  autoWidth={false}
                  autoHeight
                  lazyLoad
                  pagination
                  loop
                  dots={false}
                  autoPlay
                  nav
                  responsive={{
                    0: {
                      items: 1,
                    },
                    600: {
                      items: 3,
                    },
                    1000: {
                      items: 5,
                    },
                  }}
                  navText={["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"]}
                  className="owl-carousel-four owl-theme"
                >
                  {specials.map((special) => (
                    <div className="item">
                      <CarouselItem
                        item={special}
                      />
                    </div>
                  ))}
                </OwlCarousel>
                <Row>
                  <Col sm={12} className="text-center">
                    <Link style={{ marginTop: '9px' }} to="menu" className="btn btn-success btn-lg">
                      Full Menu
                      {' '}
                      <FontAwesome icon="chevron-circle-right" />
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        <Row>
          <Col sm={12} className="text-center">
            <Link style={{ marginTop: '9px' }} to="menu" className="btn btn-success btn-lg">
              Menu
              {' '}
              <FontAwesome icon="chevron-circle-right" />
            </Link>
          </Col>
        </Row>
      )}
    </>
  );
};
export default Index;
