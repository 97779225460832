import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CouponCard = ({
  title,
  logoImage,
  subTitle,
  imageAlt,
  imageclassName,
  morelinkUrl,
  moreLinkText,
  copyBtnText,
  couponCode,
  noBorder,
}) => (
  <div className={`card offer-card shadow-sm mb-4${noBorder ? ' border-0' : ''}`}>
    <div className="card-body">
      {(logoImage || couponCode)
        ? (
          <h5 className="card-title">
            {logoImage
              ? <Image src={logoImage} alt={imageAlt} className={imageclassName} />
              : ''}
            {couponCode || ''}
          </h5>
        )
        : ''}
      <h6 className="card-subtitle mb-2 text-block">{title}</h6>
      {subTitle
        ? (
          <p className="card-text">
            {subTitle}
          </p>
        )
        : ''}
      {copyBtnText
        ? <Button variant="link" className="card-btn mr-3 p-0">{copyBtnText}</Button>
        : ''}
      {moreLinkText
        ? <Link to={morelinkUrl} className="card-link">{moreLinkText}</Link>
        : ''}
    </div>
  </div>
);

CouponCard.propTypes = {
  title: PropTypes.string.isRequired,
  logoImage: PropTypes.string,
  subTitle: PropTypes.string,
  imageAlt: PropTypes.string,
  imageclassName: PropTypes.string,
  morelinkUrl: PropTypes.string,
  moreLinkText: PropTypes.string,
  copyBtnText: PropTypes.string,
  couponCode: PropTypes.string,
  noBorder: PropTypes.bool,
};
CouponCard.defaultProps = {
  logoImage: '',
  subTitle: '',
  imageAlt: '',
  imageclassName: '',
  morelinkUrl: '#',
  moreLinkText: 'KNOW MORE',
  copyBtnText: '',
  couponCode: '',
  noBorder: true,
};

export default CouponCard;
