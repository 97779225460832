import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Index from './components/Index';
import Offers from './components/Offers';
import Oops from './components/Oops';
import MyAccount from './components/MyAccount';
import List from './components/List';
import NotFound from './components/NotFound';
import Thanks from './components/Thanks';
import Extra from './components/Extra';
import Login from './components/Login';
import Logout from './components/Logout';
import Specials from './components/Specials';
import Register from './components/registration/Register';
import TrackOrder from './components/TrackOrder';
import Invoice from './components/Invoice';
import Checkout from './components/Checkout';
import Detail from './components/Detail';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-select2-wrapper/css/select2.css';
import './App.css';
import 'url-search-params-polyfill';
import settings from './config/settings';
import { updateFrontendSettings } from './redux/reducers/UISlice';
import EditItem from './components/EditItem';

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const checkSystemSettings = () => {
    fetch(`${settings.API_URL}/public/getCurrentFrontendOptions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
      .then((response) => {
        response.json().then((data) => {
          let frontendSettings = Object.values(data);
          frontendSettings = frontendSettings.reduce((acc, curr) => ({
            ...acc,
            [curr.option_name]: curr,
          }), {});
          dispatch(updateFrontendSettings(frontendSettings));
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {
        (history.location.pathname !== '/login' && history.location.pathname !== '/register' && history.location.pathname !== '/specials') ? <Header /> : ''
      }
      <Switch onChange={checkSystemSettings()}>
        <Route path="/" exact component={Index} />
        <Route path="/offers" exact component={Offers} />
        <Route path="/menu" exact component={List} />
        <Route path="/myaccount" component={MyAccount} />
        <Route path="/404" exact component={NotFound} />
        <Route path="/extra" exact component={Extra} />
        <Route path="/oops" exact component={Oops} />
        <Route path="/login" exact component={Login} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/register" exact component={Register} />
        <Route path="/track-order" exact component={TrackOrder} />
        <Route path="/invoice" exact component={Invoice} />
        <Route path="/checkout" exact component={Checkout} />
        <Route path="/thanks" exact component={Thanks} />
        <Route path="/item/:id" exact component={Detail} />
        <Route path="/specials" exact component={Specials} />
        <Route path="/edit/item/:id/:cartItemIndex" exact component={EditItem} />
        <Route exact component={NotFound} />
      </Switch>
      {
        (history.location.pathname !== '/login' && history.location.pathname !== '/register' && history.location.pathname !== '/specials') ? <Footer /> : ''
      }
    </>
  );
};

export default App;
