import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import {
  Row, Col, Container, Dropdown, Accordion, Button, Form, Spinner,
} from 'react-bootstrap';
import Icofont from 'react-icofont';
import settings from '../config/settings';
import PageTitle from './common/PageTitle';
import CardItem from './common/CardItem';

const List = () => {
  const user = useSelector((state) => state.user);

  const SORTTYPE = {
    0: { name: 'Default' },
    1: { name: 'Price Low > High' },
    2: { name: 'Price High > Low' },
  };
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState({
    foodTypes: [],
    specials: ['Specials', 'Regular Items'],
    allergens: ['allergen_peanut', 'allergen_treenut', 'allergen_fish', 'allergen_shellfish', 'allergen_milk', 'allergen_soy', 'allergen_wheat', 'allergen_eggs', 'allergen_sesame', 'allergen_lupin'],
  });
  const [sort, setSort] = useState(0);
  const [foodTypes, setFoodTypes] = useState([]);
  const [itemsFetched, setItemsFetched] = useState(false);

  const allergens = ['allergen_peanut', 'allergen_treenut', 'allergen_fish', 'allergen_shellfish', 'allergen_milk', 'allergen_soy', 'allergen_wheat', 'allergen_eggs', 'allergen_sesame', 'allergen_lupin'];
  const specials = ['Specials', 'Regular Items'];

  async function fetchItems() {
    await fetch(`${settings.API_URL}/private/items/getItem`, {
      headers: {
        Authorization: `Bearer ${user.userToken}`,
      },
    })
      .then((res) => res.json())
      .then((fetchedItems) => {
        setItemsFetched(true);
        if (fetchedItems.items) {
          setItems(fetchedItems.items);
          const fetchedFoodTypes = fetchedItems.items.reduce((acc, cur) => {
            if (acc.length > 0) {
              if (acc.includes(cur.ItemGroup.group_name)) {
                return acc;
              }
              acc.push(cur.ItemGroup.group_name);
              return acc;
            }
            return ([cur.ItemGroup.group_name]);
          }, []);
          setFoodTypes(fetchedFoodTypes);
          setFilters({
            ...filters,
            foodTypes: fetchedFoodTypes,
          });
        }
      });
  }

  const updateFilter = (filterName, filterValue) => {
    if (filters[filterName].includes(filterValue)) {
      setFilters({
        ...filters,
        [filterName]: filters[filterName].filter((val) => val !== filterValue),
      });
    } else {
      setFilters({
        ...filters,
        [filterName]: filters[filterName].concat([filterValue]),
      });
    }
  };

  const defaultCompare = (a, b) => {
    const groupCompare = a.ItemGroup.group_name.localeCompare(b.ItemGroup.group_name);
    if (a.is_special && !b.is_special) return -1;
    if (!a.is_special && b.is_special) return 1;
    if (groupCompare !== 0) return groupCompare;
    return a.product_name.localeCompare(b.product_name);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  if (items.length < 1 && !itemsFetched) {
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status" style={{ marginTop: '40px' }}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    );
  } if (items.length < 1 && itemsFetched) {
    return (
      <Container className="text-center">
        <p style={{ marginTop: '48px' }}>
          Ordering is currently disabled.
          If you believe this is a mistake, please contact
          {' '}
          {settings.restaurantName}
          {' '}
          and let them know.
        </p>
      </Container>
    );
  }
  return (
    <>
      <PageTitle
        title={settings.restaurantName}
        subTitle="Delicious food is only minutes away."
      />
      <section className="section products-listing" style={{ paddingTop: '0rem !important' }}>
        <Container>
          <Row className="d-none-m">
            <Col md={12}>
              <Dropdown className="float-right">
                <Dropdown.Toggle variant="outline-info">
                  Sort by:
                  {' '}
                  <span className="text-theme">{SORTTYPE[sort].name}</span>
                  {' '}
                  &nbsp;&nbsp;
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-right shadow-sm border-0">
                  <Dropdown.Item onClick={() => setSort(0)}>
                    {SORTTYPE[0].name}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setSort(1)}>
                    {SORTTYPE[1].name}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setSort(2)}>
                    {SORTTYPE[2].name}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <h4 className="font-weight-bold mt-0 mb-3">
                MEALS
                <small className="h6 mb-0 ml-2">
                  {items.length}
                  {' '}
                  items
                </small>
              </h4>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className="filters shadow-sm rounded bg-white mb-4">
                <div className="filters-header border-bottom pl-4 pr-4 pt-3 pb-3">
                  <h5 className="m-0">Filter By</h5>
                </div>
                <div className="filters-body">
                  <Accordion defaultActiveKey="0">
                    <div className="filters-card border-bottom p-4">
                      <div className="filters-card-header" id="headingOne">
                        <h6 className="mb-0">
                          <Accordion.Toggle as={Button} size="block" variant="link" className="text-left d-flex align-items-center p-0" eventKey="0">
                            Food Type
                            {' '}
                            <Icofont icon="arrow-down" className="ml-auto" />
                          </Accordion.Toggle>
                        </h6>
                      </div>
                      <Accordion.Collapse eventKey="0">
                        <div className="filters-card-body card-shop-filters">
                          {
                            foodTypes.map((foodType, index) => (
                              <Form.Check
                                key={foodType}
                                custom
                                type="checkbox"
                                defaultChecked
                                id={`type-filter-${index}`}
                                label={foodType}
                                onClick={() => updateFilter('foodTypes', foodType)}
                              />
                            ))
                          }
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="filters-card border-bottom p-4">
                      <div className="filters-card-header" id="headingTwo">
                        <h6 className="mb-0">
                          <Accordion.Toggle as={Button} size="block" variant="link" className="text-left d-flex align-items-center p-0" eventKey="1">
                            Specials
                            {' '}
                            <Icofont icon="arrow-down" className="ml-auto" />
                          </Accordion.Toggle>
                        </h6>
                      </div>
                      <Accordion.Collapse eventKey="1">
                        <div className="filters-card-body card-shop-filters">
                          {specials.map((value, index) => (
                            <Form.Check
                              // eslint-disable-next-line react/no-array-index-key
                              key={`special${index}`}
                              custom
                              type="checkbox"
                              defaultChecked
                              id={`specials-filter-${index}`}
                              label={value}
                              onClick={() => updateFilter('specials', value)}
                            />
                          ))}
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="filters-card border-bottom p-4">
                      <div className="filters-card-header" id="headingOne">
                        <h6 className="mb-0">
                          <Accordion.Toggle as={Button} size="block" variant="link" className="text-left d-flex align-items-center p-0" eventKey="2">
                            Allergens
                            {' '}
                            <Icofont icon="arrow-down" className="ml-auto" />
                          </Accordion.Toggle>
                        </h6>
                      </div>
                      <Accordion.Collapse eventKey="2">
                        <div className="filters-card-body card-shop-filters">
                          {
                            allergens.map((allergen, index) => (
                              <Form.Check
                                key={allergen}
                                custom
                                type="checkbox"
                                defaultChecked
                                id={`allergen-filter-${index}`}
                                label={allergen.split('_')[1].replace(/\b[a-z]/g, (x) => x.toUpperCase())}
                                value={allergen}
                                onClick={() => updateFilter('allergens', allergen)}
                              />
                            ))
                          }
                        </div>
                      </Accordion.Collapse>
                    </div>
                  </Accordion>
                </div>
              </div>
            </Col>
            <Col md={9}>
              <Row>
                {items.length > 0
                  ? items.filter((item) => {
                    const specialCheck = !((filters.specials.includes('Specials') && item.is_special) || (filters.specials.includes('Regular Items') && !item.is_special));
                    const allergenList = allergens.filter((x) => !filters.allergens.includes(x));
                    const allergenCheck = allergenList.reduce((acc, current) => !item[current] && acc, true);
                    if (!filters.foodTypes.includes(item.ItemGroup.group_name)) {
                      return false;
                    } if (specialCheck) {
                      return false;
                    } if (!allergenCheck) {
                      return false;
                    }
                    return true;
                  }).sort((a, b) => {
                    switch (sort) {
                      case 0:
                        return defaultCompare(a, b);
                      case 1:
                        return a.price - b.price;
                      case 2:
                        return b.price - a.price;
                      default:
                        return 0;
                    }
                  }).map((item) => (
                    <Col md={4} sm={6} className="mb-4 pb-2" key={item.product_name}>
                      <CardItem
                        title={item.product_name}
                        subTitle={item.description}
                        imageAlt="Product"
                        image={item.image_url ? item.image_url : 'img/food_icon.png'}
                        imageClass="img-fluid item-img"
                        linkUrl={`item/${item.item_id}`}
                        time={item.preperation_time_seconds > 0 ? `~${item.preperation_time_seconds / 60} Mins` : null}
                        price={`$${item.price.toFixed(2)}`}
                        showPromoted={item.is_special}
                        promotedVariant="dark"
                      />
                    </Col>
                  ))
                  : null}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default List;
