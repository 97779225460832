import React from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// {
// title,
// subTitle,
// className,
//  image,
//  imageClass,
//  _imageAlt,
//  _badgeClass,
//  _badgeValue,
// linkUrl,
// linkText,
// }
const CartDropdownHeader = () => (
  <>
    {/* <div className={className}>
      {
        linkUrl
          ? (
            <small>
              <Link className="text-primary font-weight-bold" to={linkUrl}>{linkText}</Link>
            </small>
          ) : ''
      }
    </div> */}
  </>
);

CartDropdownHeader.propTypes = {
  // title: PropTypes.string.isRequired,
  // subTitle: PropTypes.string,
  // className: PropTypes.string,
  // image: PropTypes.string,
  // imageClass: PropTypes.string,
  // imageAlt: PropTypes.string,
  // badgeClass: PropTypes.string,
  // badgeValue: PropTypes.number,
  // linkUrl: PropTypes.string,
  // linkText: PropTypes.string,
};

CartDropdownHeader.defaultProps = {
  // subTitle: '',
  // className: '',
  // imageAlt: '',
  // image: '',
  // imageClass: '',
  // badgeClass: '',
  // badgeValue: 0,
  // linkUrl: '',
  // linkText: '',
};

export default CartDropdownHeader;
