import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const DropdownItemOption = ({
  itemClass, setValue, itemOptionGroup, getValue,
}) => {
  const cart = useSelector((state) => state.cart);
  const { cartItemIndex } = useParams();
  const changeValue = (e) => {
    if (e.target.value !== 'NO_VALUE_SELECTED') {
      setValue({
        id: e.currentTarget.value,
        value: true,
        price: itemOptionGroup.ItemOptions
          .find((itemOption) => itemOption.item_option_id === parseInt(e.currentTarget.value, 10)).price,
        product_name: itemOptionGroup.ItemOptions
          .find((itemOption) => itemOption.item_option_id === parseInt(e.currentTarget.value, 10)).product_name,
        group_id: itemOptionGroup.item_option_group_id,
        group_type: itemOptionGroup.group_type,
      });
    }
  };

  return (
    <div className={`p-3 border-bottom ${itemClass}`}>
      <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Control
          as="select"
          onChange={changeValue}
          defaultValue="NO_VALUE_SELECTED"
        >
          <option disabled={itemOptionGroup.mandatory} value="NO_VALUE_SELECTED">Please select an option</option>
          {
            itemOptionGroup.ItemOptions.map((itemOption) => {
              const displayText = `${itemOption.product_name} ${itemOption.price === 0 ? '' : `- ${itemOption.price}`}`;
              return (
                <option
                  selected={cart?.items?.[cartItemIndex]?.[itemOption.item_option_group_id].ItemOptions ? getValue(Object.values(cart?.items?.[cartItemIndex]?.[itemOption.item_option_group_id].ItemOptions).find((io) => io.group_type === 'DROPDOWN')?.id)?.id === itemOption.item_option_id.toString() : null}
                  key={`itemOption${itemOption.item_option_id}`}
                  value={itemOption.item_option_id}
                >
                  {displayText}
                </option>
              );
            })
          }
        </Form.Control>
      </Form.Group>
    </div>
  );
};
export default DropdownItemOption;

DropdownItemOption.propTypes = {
  itemClass: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  itemOptionGroup: PropTypes.object,
};

DropdownItemOption.defaultProps = {
  itemClass: '',
  itemOptionGroup: {
    group_type: '',
    item_option_group_id: '',
    ItemOptions: {
      price: 0,
      product_name: '',
      item_option_id: '',
    },
  },
};
