/* eslint-disable prefer-template */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Media } from 'react-bootstrap';
import Icofont from 'react-icofont';
import formatDate from './FormatDate';

const OrderCard = ({
  order,
}) => {
  const history = useHistory();

  return (
    <div className="bg-white card mb-4 order-list shadow-sm">
      <div className="gold-members p-4">
        <Media onClick={() => {
          history.push(`orders/invoice/${order.order_id}`);
        }}
        >
          <Media.Body>
            <h6 className="mb-2">
              {order.status}
            </h6>
            <p className="text-gray mb-3">
              <Icofont icon="list" />
              {' '}
              ORDER #
              {order.order_id}
              <Icofont icon="clock-time" className="ml-2" />
              {' '}
              {formatDate(order.createdAt)}
            </p>
            {order?.OrderDetails
              .map((orderDetail) => (
                <p className="text-dark">
                  {orderDetail.quantity > 0 ? orderDetail.quantity + ' x ' : ''}
                  {orderDetail.Item.product_name}
                </p>
              ))}
            <hr />
            <div className="float-right" />
            <p className="mb-0 text-black text-primary pt-2">
              <span className="text-black font-weight-bold"> Total Paid:</span>
              {' $'}
              {order.total}
            </p>
            <p className="mb-0 text-black text-primary pt-2">
              <span className="text-black font-weight-bold"> Item count:</span>
              {' '}
              {order.item_total}
            </p>
          </Media.Body>
        </Media>
      </div>
    </div>
  );
};

OrderCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropTypes.object.isRequired,
};

OrderCard.defaultProps = {
};
export default OrderCard;
