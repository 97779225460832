import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Jumbotron, Container } from 'react-bootstrap';
import settings from '../config/settings';
import { setSpecials, clearSpecials } from '../redux/reducers/specialSlice';
import useInterval from '../helpers/useInterval';
import logo from '../img/TheV.png';

// TODO: Make this lookk better
const Specials = () => {
  const specials = useSelector((state) => state.special.specials);
  const dispatch = useDispatch();

  useEffect(() => {
    // side effects
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch(`${settings.API_URL}/public/items/getSpecials`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Unauthorized');
      })
      .then((data) => {
        dispatch(setSpecials(data.specials));
      })
      .catch((error) => {
        console.log(error);
      });

    // cleanup
    return () => {
      dispatch(clearSpecials());
    };
  }, []);

  useInterval(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch(`${settings.API_URL}/public/items/getSpecials`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Unauthorized');
      })
      .then((data) => {
        dispatch(setSpecials(data.specials));
      })
      .catch((error) => {
        console.log(error);
      });
  }, 10000);

  document.body.style.overflow = 'hidden';

  return (
    <div className="background">
      <div
        className="specialsHeader"
        style={{
          paddingBottom: '5vh',
        }}
      >
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="jumbotronWrapper">
        {specials.length > 0 ? specials.map((special) => (
          <Jumbotron style={{
            minWidth: '100vw',
            height: `${72 / (specials.length + 1)}vh`,
          }}
          >
            <Container style={{
              minWidth: '100%',
            }}
            >
              <div className="specialsItem">
                <div className="specialsText">
                  <p className="specialsName">
                    {special.product_name}
                    {' '}
                  </p>
                  <p className="specialsDescription">
                    {special.description}
                  </p>
                </div>
                <p className="specialsPrice">
                  $
                  {special.price}
                </p>
              </div>
            </Container>
          </Jumbotron>
        )) : null}
      </div>
    </div>
  );
};

export default Specials;
