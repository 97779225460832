import React from 'react';
import PropTypes from 'prop-types';

const HeadingValue = ({
  className, heading, value, valueClassName,
}) => (
  <p className={`mb-1 ${className}`}>
    {heading}
    {value
      ? <strong className={valueClassName}>{value}</strong>
      : ''}
  </p>
);

HeadingValue.propTypes = {
  heading: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  valueClassName: PropTypes.string,
};
HeadingValue.defaultProps = {
  value: '',
  className: '',
  valueClassName: '',
};

export default HeadingValue;
