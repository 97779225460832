import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';

const CarouselItem = ({
  item,
}) => (
  <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
    <div className="list-card-image">
      {item.is_special ? (
        <div className="star position-absolute">
          <Badge variant="success">
            Special
          </Badge>
        </div>
      )
        : ''}
      {item.ItemGroup.group_name ? (
        <div className="member-plan position-absolute">
          <Badge variant="dark">{item.ItemGroup.group_name}</Badge>
        </div>
      )
        : ''}
      <Link to={`item/${item.item_id}`}>
        <Image
          src={item.image_url ? item.image_url : 'img/earn-score-icon.png'}
          className="img-fluid item-img"
          alt={item.product_name}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Link>
    </div>
    <div className="p-3 position-relative">
      <div className="list-card-body">
        <h6 className="mb-1"><Link to={`item/${item.item_id}`} className="text-black">{item.product_name}</Link></h6>
        {item.description ? (
          <p className="text-gray mb-3" style={{ height: '100px', textOverflow: 'ellipsis' }}>
            {item.description}
          </p>
        )
          : (
            <p className="text-gray mb-3" style={{ height: '100px', textOverflow: 'ellipsis' }} />
          )}
        {(item.preperation_time_seconds > 0 || item.price) ? (
          <p style={{ marginBottom: '0px' }}>
            {item.preperation_time_seconds ? (
              <span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2">
                <Icofont icon="wall-clock" />
                {' '}
                ~
                {item.preperation_time_seconds / 60}
                {' '}
                Mins
              </span>
            )
              : ''}
            {item.price ? (
              <span className="float-right text-black-50 " style={{ marginBottom: '16px' }}>
                $
                {item.price}
              </span>
            )
              : ''}
          </p>
        ) : ''}
      </div>
    </div>
  </div>
);

CarouselItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
};
CarouselItem.defaultProps = {
};

export default CarouselItem;
