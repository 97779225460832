import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';

const CardItem = ({
  rating,
  linkUrl,
  imageClass,
  imageAlt,
  promotedVariant,
  showPromoted,
  subTitle,
  title,
  image,
  time,
  price,
  offerText,
  offerColor,
}) => (
  <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
    <div className="list-card-image">
      {rating ? (
        <div className="star position-absolute">
          <Badge variant="success">
            <Icofont icon="star" />
            {' '}
            {rating}
          </Badge>
        </div>
      )
        : ''}
      {showPromoted ? (
        <div className="member-plan position-absolute">
          <Badge variant={promotedVariant}>Special</Badge>
        </div>
      )
        : ''}
      <Link to={linkUrl}>
        <Image
          src={image}
          className={imageClass}
          alt={imageAlt}
          style={{
            width: '100%',
            height: '250px',
            objectFit: 'cover',
          }}
        />
      </Link>
    </div>
    <div className="p-3 position-relative">
      <div className="list-card-body">
        <h6 className="mb-1"><Link to={linkUrl} className="text-black">{title}</Link></h6>
        {subTitle ? (
          <p className="text-gray mb-3" style={{ height: '100px', textOverflow: 'ellipsis' }}>
            {subTitle}
          </p>
        )
          : ''}
        {(time || price) ? (
          <p className="text-gray mb-3 time">
            {time ? (
              <span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2">
                <Icofont icon="wall-clock" />
                {' '}
                {time}
              </span>
            )
              : <div style={{ paddingBottom: '8px' }} />}
            {price ? (
              <span className="float-right text-black-50">
                {' '}
                {price}
              </span>
            )
              : ''}
          </p>
        ) : ''}
      </div>
      {offerText ? (
        <div className="list-card-badge">
          <Badge variant={offerColor}>OFFER</Badge>
          {' '}
          <small>{offerText}</small>
        </div>
      )
        : null}
    </div>
  </div>
);

CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  image: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  linkUrl: PropTypes.string.isRequired,
  offerText: PropTypes.string,
  offerColor: PropTypes.string,
  subTitle: PropTypes.string,
  time: PropTypes.string,
  price: PropTypes.string,
  showPromoted: PropTypes.bool,
  promotedVariant: PropTypes.string,
  rating: PropTypes.string,
};

CardItem.defaultProps = {
  imageAlt: '',
  imageClass: '',
  offerText: '',
  offerColor: 'success',
  subTitle: '',
  time: '',
  price: '',
  showPromoted: false,
  promotedVariant: 'dark',
  rating: '',
};

export default CardItem;
