import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userSlice';
import UIReducer from './reducers/UISlice';
import checkoutReducer from './reducers/checkoutSlice';
import cartReducer from './reducers/cartSlice';
import orderReducer from './reducers/orderSlice';
import specialReducer from './reducers/specialSlice';
import AlertReducer from './reducers/AlertSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    UI: UIReducer,
    checkout: checkoutReducer,
    cart: cartReducer,
    order: orderReducer,
    special: specialReducer,
    alertState: AlertReducer,
  },
});
