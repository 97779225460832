import { createSlice } from '@reduxjs/toolkit';
import { deepmerge } from 'deepmerge';

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    items: {},
    itemCount: 0,
  },
  reducers: {
    addOrEditItems: (state, action) => {
      if (action.payload.psItemID) {
        state.items = deepmerge(state.items, {
          [action.payload.psItem]: action.payload.item,
        });
      } else {
        state.items = deepmerge(state.items, {
          [state.itemCount]: action.payload.item,
        });
        state.itemCount += 1;
      }
    },
    resetCheckout: (state) => {
      state.userEmail = '';
      state.userID = '';
      state.userToken = '';
      state.isUserLoggedIn = false;
    },
  },
});

export const {
  setUserEmail, setUserID, resetUserState, setIsUserLoggedIn, setUserToken,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
