/* eslint-disable prefer-template */
import React from 'react';
import {
  Row, Col,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import CheckboxItemOption from './itemOptionTypes/CheckboxItemOption';
import DropdownItemOption from './itemOptionTypes/DropdownItemOption';
import QuantityItemOption from './itemOptionTypes/QuantityItemOption';

const ItemOptionGroup = ({ itemOptionGroup, setValue, getValue }) => {
  const renderOptionGroup = (itemOption) => {
    switch (itemOptionGroup.group_type) {
      case 'CHECKBOX':
        return (
          <CheckboxItemOption key={itemOption?.item_option_id + 'itemOptionKeyCheck'} setValue={setValue} getValue={getValue} itemOption={itemOption} />
        );
      case 'DROPDOWN':
        return (
          <DropdownItemOption key={itemOption?.item_option_id + 'itemOptionKeyDrop'} setValue={setValue} getValue={getValue} itemOptionGroup={itemOptionGroup} />
        );
      case 'QUANTITY':
        return (
          <QuantityItemOption key={itemOption?.item_option_id + 'itemOptionKeyQuantity'} setValue={setValue} getValue={getValue} itemOption={itemOption} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Row>
        <h5 className="mb-4 mt-3 col-md-12">
          {itemOptionGroup.group_name}
          {itemOptionGroup.group_type !== 'DROPDOWN'
            ? (
              <small className="h6 text-black-50">
                {' '}
                {itemOptionGroup.ItemOptions.length}
                {' '}
                {itemOptionGroup.ItemOptions.length > 1 ? 'ITEMS' : 'ITEM'}
              </small>
            ) : null}
          {itemOptionGroup.mandatory ? '*' : null}
        </h5>
        <Col md={12}>
          <div className="bg-white rounded border shadow-sm">
            {
              itemOptionGroup.group_type !== 'DROPDOWN'
                ? itemOptionGroup.ItemOptions.map((itemOption) => renderOptionGroup(itemOption))
                : renderOptionGroup()
            }
          </div>
        </Col>
      </Row>
    </>
  );
};
export default ItemOptionGroup;

ItemOptionGroup.defaultProps = {
};

ItemOptionGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  itemOptionGroup: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
};
