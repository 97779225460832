import React from 'react';
import PropTypes from 'prop-types';
import { Image, Badge } from 'react-bootstrap';
import FontAwesome from './FontAwesome';

const DropDownTitle = ({
  title,
  faIcon,
  iconClass,
  className,
  image,
  imageAlt,
  imageClass,
  badgeVariant,
  badgeClass,
  badgeValue,
}) => (
  <div className={className}>
    {
      image
        ? <Image alt={imageAlt} src={image} className={imageClass} />
        : ''
    }
    {(faIcon && !image)
      ? <FontAwesome icon={faIcon} className={iconClass} /> : ''}
    {title}
    {badgeValue
      ? <Badge variant={badgeVariant} className={badgeClass}>{badgeValue}</Badge>
      : ''}
  </div>
);

DropDownTitle.propTypes = {
  title: PropTypes.string.isRequired,
  faIcon: PropTypes.string,
  iconClass: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  imageClass: PropTypes.string,
  badgeVariant: PropTypes.string,
  badgeClass: PropTypes.string,
  badgeValue: PropTypes.number,
};

DropDownTitle.defaultProps = {
  faIcon: 'shopping-basket',
  iconClass: '',
  className: '',
  imageAlt: '',
  image: '',
  imageClass: '',
  badgeVariant: '',
  badgeClass: '',
  badgeValue: 0,
};

export default DropDownTitle;
