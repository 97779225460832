import React, { useEffect, useState } from 'react';
import {
  Row, Col, Container, Button, Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import settings from '../../config/settings';

const RegistrationStage2 = (props) => {
  const {
    registrationDetails, resetForm, updateRegistrationStage, setAccessToken, setConfirmationAlert,
  } = props;

  const [tempDisableResendButton, setTempDisableResendButton] = useState(false);

  // Every 6 seconds, check whether the email address has been confirmed.
  useEffect(() => {
    const checkEmailConfirmed = () => {
      fetch(`${settings.API_URL}/public/checkEmailConfirmed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({
          email: registrationDetails.email,
        }),
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((responseAsJSON) => {
              setAccessToken(responseAsJSON.accessToken);
              updateRegistrationStage(100);
            });
          }
        })
        .catch((error) => console.log(error));
    };
    const interval = setInterval(checkEmailConfirmed, 6000);

    return () => clearInterval(interval);
  }, [registrationDetails]);

  const handleRequestEmailResend = () => {
    setTempDisableResendButton(true);
    fetch(`${settings.API_URL}/public/requestConfirmEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        email: registrationDetails.email,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return response.json();
      }).then((responseAsJSON) => {
        setConfirmationAlert(responseAsJSON.message);
      })
      .catch((error) => console.log(error));
    setTimeout(() => { setTempDisableResendButton(false); }, 5000);
  };

  return (
    <div className="login d-flex align-items-center py-5">

      <Container>
        <Row>
          <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
            <span>
              <h3 className="login-heading mb-4">Please confirm your email</h3>
              <Spinner className="mb-2" animation="border" />
            </span>
            <p style={{ color: 'white' }} className="login-heading">
              An email with a confirmation link has been sent to
              {' '}
              <strong>{registrationDetails.email}</strong>
              . If you cannot see the link,
              please check your junk email. Once confirmed, this stage will automatically progress.
            </p>
            <Button onClick={() => handleRequestEmailResend()} disabled={tempDisableResendButton}>Resend</Button>
            <div className="text-left pt-3">
              Not your email?
              {' '}
              { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link className="font-weight-bold" onClick={() => resetForm()} to="#">Go Back</Link>
            </div>
          </Col>
        </Row>

      </Container>
    </div>
  );
};

RegistrationStage2.propTypes = {
  registrationDetails: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  resetForm: PropTypes.func.isRequired,
  updateRegistrationStage: PropTypes.func.isRequired,
  setAccessToken: PropTypes.func.isRequired,
  setConfirmationAlert: PropTypes.string.isRequired,
};

export default RegistrationStage2;
