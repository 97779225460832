import React, { useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import settings from '../../config/settings';

const EditProfileModal = ({ show, onHide }) => {
  const user = useSelector((state) => state.user);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [changePasswordResponse, setChangePasswordResponse] = useState('');

  async function changePassword(token, email, newPass, newPassConfirm, currPass) {
    const promise = new Promise(((resolve, reject) => {
      fetch(`${settings.API_URL}/private/changePassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          email,
          newPassword: newPass,
          newPasswordConfirm: newPassConfirm,
          currentPassword: currPass,
        }),
      }).then((response) => response.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }));
    return promise;
  }

  const handleChangePasswordButton = (e) => {
    e.preventDefault();
    if (newPassword === '' || newPasswordConfirm === '' || currentPassword === '') {
      setChangePasswordResponse('Password fields can\'t be empty');
    } else if (newPasswordConfirm !== newPassword) {
      setChangePasswordResponse('Confirmation password does not match');
    } else {
      changePassword(user.userToken, user.userEmail, newPassword, newPasswordConfirm, currentPassword)
        .then((response) => {
          if (response.message !== 'Your password has been successfully changed') {
            setChangePasswordResponse(response.message);
          } else {
            onHide();
            setChangePasswordResponse('');
          }
        }).catch((error) => console.log(error));
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5" id="edit-profile">
          {user.userEmail}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <div className="form-row">
            {/* <Form.Group className="col-md-12">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type="text" defaultValue={user.userPhoneNumber} placeholder="Enter Phone number" />
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="text"
                defaultValue={user.userEmail}
                placeholder="Enter Email id
                        "
              />
            </Form.Group> */}
            <Form.Group className="col-md-12 mb-0">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter current password"
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-md-12 mb-0">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="col-md-12 mb-0">
              <Form.Label>New Password Confirmation</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password confirmation"
                onChange={(e) => setNewPasswordConfirm(e.target.value)}
              />
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      {changePasswordResponse !== '' ? (
        <p style={{ paddingLeft: '16px', color: 'white' }}>
          {changePasswordResponse}
        </p>
      ) : null}
      <Modal.Footer>
        <Button
          type="button"
          onClick={onHide}
          variant="outline-primary"
          className="d-flex w-50 text-center justify-content-center"
        >
          CANCEL

        </Button>
        <Button
          onClick={(e) => handleChangePasswordButton(e)}
          type="button"
          variant="primary"
          className="d-flex w-50 text-center justify-content-center"
        >
          UPDATE

        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default EditProfileModal;

EditProfileModal.propTypes = {
  show: propTypes.func.isRequired,
  onHide: propTypes.func.isRequired,
};
