import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row, Col, Container, Image,
} from 'react-bootstrap';

const Oops = () => (
  <section className="section pt-5 pb-5 osahan-not-found-page">
    <Container>
      <Row>
        <Col md={12} className="text-center pt-5 pb-5">
          <Image className="img-fluid" src="/img/404.png" alt="404" />
          <h1 className="mt-2 mb-2">Oops</h1>
          <p className="mb-5">It looks like that payment failed</p>
          <Link className="btn btn-primary btn-lg" to="/checkout">TRY AGAIN</Link>
        </Col>
      </Row>
    </Container>
  </section>
);

export default Oops;
