import React, { useState } from 'react';
import {
  Switch, Route, NavLink,
} from 'react-router-dom';
import {
  Row, Col, Container, Image, Button,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Orders from './myaccount/Orders';
import EditProfileModal from './modals/EditProfileModal';
import Invoice from './Invoice';

const MyAccount = () => {
  const [showEditProfile, setShowEditProfile] = useState(false);
  const hideEditProfile = () => setShowEditProfile(false);
  const user = useSelector((state) => state.user);

  return (
    <>
      <EditProfileModal show={showEditProfile} onHide={hideEditProfile} />
      <section className="section pt-4 pb-4 osahan-account-page">
        <Container>
          <Row>
            <Col md={3}>
              <div className="osahan-account-page-left shadow-sm bg-white h-100">
                <div className="border-bottom p-4">
                  <div className="osahan-user text-center">
                    <div className="osahan-user-media">
                      <Image
                        className="mb-3 rounded-pill shadow-sm mt-1"
                        src="/img/user.png"
                        alt={user.userFirstName}
                      />
                      <div className="osahan-user-media-body">
                        <h6 className="mb-2">
                          {user.userFirstName}
                          {' '}
                          {user.userLastName}
                        </h6>
                        <p className="mb-1">{user.userPhoneNumber}</p>
                        <p>{user.userEmail}</p>
                        <p className="mb-0 text-black font-weight-bold">
                          <Button
                            onClick={() => setShowEditProfile(true)}
                            variant="success"
                            style={{ color: 'white!important' }}
                          >
                            EDIT
                          </Button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="nav flex-column border-0 pt-4 pl-4 pb-4">
                  <li className="nav-item">
                    <NavLink className="nav-link" activeClassName="active" to="/myaccount/orders">
                      <i className="icofont-food-cart" />
                      {' '}
                      Orders
                    </NavLink>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={9}>
              <Switch>
                <Route path="/myaccount/orders" exact component={Orders} />
                <Route path="/myaccount/orders/invoice/:id" exact component={Invoice} />
              </Switch>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default MyAccount;
