import { createSlice } from '@reduxjs/toolkit';

const getInitialUserDetails = () => {
  const userToken = JSON.parse(localStorage.getItem('OAPPTOKEN'));
  if (userToken) {
    return {
      userEmail: userToken.user.email,
      userID: userToken.user.user_id,
      userToken: userToken.token,
      userFirstName: userToken.user.first_name,
      userLastName: userToken.user.last_name,
      emailConfirmed: userToken.user.email_confirmed,
      phoneConfirmed: userToken.user.phone_confirmed,
      userPhoneNumber: userToken.user.phone_number,
      permissionLevel: userToken.user.permission_level,
      isUserLoggedIn: true,
    };
  }
  return {
    userEmail: '',
    userID: 0,
    userToken: '',
    userFirstName: '',
    userLastName: '',
    emailConfirmed: false,
    phoneConfirmed: false,
    userPhoneNumber: '',
    permissionLevel: 1,
    isUserLoggedIn: false,
  };
};

export const userSlice = createSlice({
  name: 'user',
  initialState: getInitialUserDetails(),
  reducers: {
    setIsUserLoggedIn: (state, action) => {
      state.isUserLoggedIn = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setUserID: (state, action) => {
      state.userID = action.payload;
    },
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
    setUserFirstName: (state, action) => {
      state.userFirstName = action.payload;
    },
    setUserLastName: (state, action) => {
      state.userLastName = action.payload;
    },
    setEmailConfirmed: (state, action) => {
      state.emailConfirmed = action.payload;
    },
    setPhoneConfirmed: (state, action) => {
      state.phoneConfirmed = action.payload;
    },
    setUserPhoneNumber: (state, action) => {
      state.userPhoneNumber = action.payload;
    },
    setPermissionLevel: (state, action) => {
      state.permissionLevel = action.payload;
    },
    resetUserState: (state) => {
      window.localStorage.removeItem('OAPPTOKEN');
      state.userEmail = '';
      state.userID = '';
      state.userToken = '';
      state.isUserLoggedIn = false;
      state.userFirstName = '';
      state.userLastName = '';
      state.emailConfirmed = false;
      state.phoneConfirmed = false;
      state.userPhoneNumber = '';
      state.permissionLevel = false;
    },
  },
});

export const {
  setUserEmail,
  setUserID,
  resetUserState,
  setIsUserLoggedIn,
  setUserToken,
  setUserFirstName,
  setUserLastName,
  setEmailConfirmed,
  setPhoneConfirmed,
  setUserPhoneNumber,
  setPermissionLevel,
} = userSlice.actions;

export default userSlice.reducer;
