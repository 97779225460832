import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container, Spinner, Row, Col,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Icofont from 'react-icofont';
import { setOrders, clearOrders } from '../../redux/reducers/orderSlice';
import OrderCard from '../common/OrderCard';
import settings from '../../config/settings';
import DropDownItem from '../cart/DropDownItem';
import formatDate from '../common/FormatDate';
import FontAwesome from '../common/FontAwesome';
import useInterval from '../../helpers/useInterval';

const Orders = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const pastOrders = useSelector((state) => state.order.orders);
  const currentOrders = useSelector((state) => (Object.fromEntries(
    Object.entries(state.order.orders).filter(([_key, value]) => value.stage !== 'ORDERFINALISED'),
  )));
  const [ordersFetched, setOrdersFetched] = useState(false);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user.userToken}` },
    };

    fetch(`${settings.API_URL}/private/order/getMyOrderDetails`, requestOptions)
      .then((response) => {
        if (response.ok) {
          setOrdersFetched(true);
          return response.json();
        }
        setOrdersFetched(true);
        throw new Error('Unauthorized');
      })
      .then((data) => {
        dispatch(setOrders(data.orderDetails));
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      dispatch(clearOrders());
    };
  }, []);

  useInterval(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user.userToken}` },
    };

    fetch(`${settings.API_URL}/private/order/getMyOrderDetails`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Unauthorized');
      })
      .then((data) => {
        if (JSON.stringify(data.orderDetails) !== JSON.stringify(pastOrders)) {
          dispatch(setOrders(data.orderDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, 10000);

  if (pastOrders.length > 0 && ordersFetched) {
    return (
      <>
        <div className="p-4 bg-white shadow-sm">
          {Object.keys(currentOrders).length > 0 ? (
            <>
              <h4 className="font-weight-bold mt-0 mb-4">
                Current Order
                {Object.keys(currentOrders).length > 1 ? 's' : ''}
              </h4>
              {Object.values(currentOrders).map((order) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
                <div
                  role="button"
                  className="bg-dark"
                  style={{ marginBottom: '24px' }}
                  onClick={() => {
                    history.push(`orders/invoice/${order.order_id}`);
                  }}
                >
                  <div className="bg-dark p-4 mb-2">
                    <div className="mb-2">
                      <small>
                        Order #
                        {order.order_id}
                      </small>
                      <div className="float-right">
                        {
                          order.stage === 'RECEIVED'
                          || order.stage === 'AWAITINGPAYMENT'
                          || order.stage === 'PAID'
                            || order.stage === 'SENTTOKITCHEN' ? (
                              <>
                                <Icofont icon="tasks" className="icofont-3x text-info" />
                                <p className="mt-1 font-weight-bold text-dark mb-0">
                                  Order Received
                                </p>
                                <small className="text-info mb-0">
                                  {formatDate(order.createdAt)}
                                </small>

                              </>
                            )
                            : null
                        }
                        {
                          order.stage === 'ORDERSTARTED'
                            || order.stage === 'ORDERCOMPLETED' ? (
                              <>
                                <Icofont icon="check-circled" className="icofont-3x text-success" />
                                <p className="mt-1 font-weight-bold text-dark mb-0">
                                  Order Started
                                </p>
                                <small className="text-success mb-0">{formatDate(order.updatedAt)}</small>
                              </>
                            )
                            : null
                        }
                        {
                          order.stage === 'CUSTOMERNOTIFIED'
                            || order.stage === 'CUSTOMERRENOTIFIED'
                            || order.stage === 'ORDERPICKEDUP' ? (
                              <>
                                <Icofont icon="bell" className="icofont-3x text-primary" />
                                <p className="mt-1 font-weight-bold text-dark mb-0">Awaiting Pickup</p>
                                <small className="text-primary mb-0">
                                  {formatDate(order.updatedAt)}
                                </small>
                              </>
                            )
                            : null
                        }
                      </div>
                    </div>
                    <h6 className="mb-1 mt-1">
                      <Link to="/home" className="text-black">
                        {settings.restaurantName}
                      </Link>
                    </h6>
                    <p className="text-gray mb-0">
                      <Icofont icon="clock-time" />
                      {' '}
                      {formatDate(order.createdAt)}
                      {' '}
                      |
                      {' '}
                      {order.item_total}
                      {' '}
                      Item
                      {order.item_total > 1 ? 's' : ''}
                      {' '}
                      | $
                      {order.total.toFixed(2)}
                    </p>
                  </div>
                  <div className="bg-dark p-4 ">
                    <div className="osahan-track-order-detail po">
                      <h5 className="mt-0 mb-3">Order Details</h5>
                      <Row>
                        <Col md={5}>
                          <small>FROM</small>
                          <h6 className="mb-1 mt-1">
                            <Link to="/detail" className="text-black">
                              <Icofont icon="food-cart" />
                              {' '}
                              {settings.restaurantName}
                            </Link>
                          </h6>
                          <p className="text-gray mb-5">{settings.restaurantLocation}</p>
                        </Col>
                        <Col md={7}>
                          <div className="mb-2">
                            <small>
                              <Icofont icon="list" />
                              {' '}
                              {order.item_total}
                              {' '}
                              Item
                              {order.item_total > 1 ? 's' : ''}
                            </small>
                          </div>
                          {order.OrderDetails.map((orderDetail) => (
                            <DropDownItem
                              key={`${Object.keys(orderDetail)[0]}checkOutDropDownItem`}
                              orderDetail={orderDetail}
                            />
                          ))}
                          <hr />
                          <p className="mb-0 font-weight-bold text-black">
                            TOTAL
                            <span className="float-right text-secondary">
                              $
                              {order.total.toFixed(2)}
                            </span>
                          </p>
                          <p className="mb-0 text-info">
                            {order.stage !== 'RECEIVED' && order.stage !== 'AWAITINGPAYMENT' ? (
                              <small>
                                Paid via Credit/Debit card
                              </small>
                            ) : (
                              <small className="text-primary mb-0">
                                Not Paid
                              </small>
                            )}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : null}
          <h4 className="font-weight-bold mt-0 mb-4">Past Orders</h4>
          {pastOrders.map((order) => (
            <OrderCard
              key={`${order.order_id}}orderCard`}
              order={order}
            />
          )).sort((a, b) => {
            if (a.props.order.createdAt < b.props.order.createdAt) return 1;
            if (a.props.order.createdAt > b.props.order.createdAt) return -1;
            return 0;
          })}
        </div>
      </>
    );
  } if (!ordersFetched) {
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status" style={{ marginTop: '40px' }}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    );
  }
  return (
    <Row>
      <Col sm={12} className="text-center">
        <p style={{ marginTop: '32px' }}>
          It doesn&apos;t look like you have any past orders. Please check out the menu to fix that.
        </p>
        <Link style={{ marginTop: '9px' }} to="/menu" className="btn btn-success btn-lg">
          Menu
          {' '}
          <FontAwesome icon="chevron-circle-right" />
        </Link>
      </Col>
    </Row>
  );
};
export default Orders;
