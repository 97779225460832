import React, { useEffect, useState } from 'react';
import {
  Row, Col, Container, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import settings from '../../config/settings';

const RegistrationStage3 = (props) => {
  const { registrationDetails, accessToken } = props;
  const [requestSent, setRequestSent] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const history = useHistory();

  // When the component is mounted, request the backend to send an SMS to the number in the form
  useEffect(() => {
    if (!requestSent && accessToken && accessToken !== '') {
      fetch(`${settings.API_URL}/private/requestConfirmPhone`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          mobileNumber: registrationDetails.mobileNumber,
        }),
      })
        .then((response) => {
          if (response.ok) {
            setRequestSent(true);
            // TOOD: wait
          } else {
            // TODO: Display error
          }
        })
        .catch((err) => console.log(err));
    }
  }, [registrationDetails,
    accessToken]);

  const navigateToLogin = () => {
    history.push('/login');
  };

  const handleConfirmPhone = () => {
    fetch(`${settings.API_URL}/private/confirmPhone`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        SMSVerificationCode: confirmationCode,
      }),
    }).then((response) => {
      if (response.ok) {
        navigateToLogin();
      }
    });
  };

  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      handleConfirmPhone();
    }
  };

  return (
    <div className="login d-flex align-items-center py-5">
      <Container>
        <Row>
          <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
            <h3 className="login-heading mb-4">Please confirm your mobile</h3>
            <p style={{ color: 'white' }} className="login-heading">
              An SMS has been sent to
              {' '}
              <strong>{registrationDetails.mobileNumber}</strong>
              . Please enter it here. If you do not wish to receive
              mobile notifications, you may skip this step.
            </p>
            <input
              onKeyPress={handleKeyPress}
              style={{
                height: '40px',
                borderRadius: '6px',
                verticalAlign: 'middle',
              }}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
            <Button style={{ margin: '5px' }} onClick={() => handleConfirmPhone()}>Confirm</Button>
            <Button onClick={() => navigateToLogin()}>Skip</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

RegistrationStage3.propTypes = {
  registrationDetails: PropTypes.shape({
    mobileNumber: PropTypes.string.isRequired,
  }).isRequired,
  accessToken: PropTypes.string.isRequired,
};

export default RegistrationStage3;
