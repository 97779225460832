import React from 'react';
import PropTypes from 'prop-types';

const PageTitle = ({ subTitle, title }) => (
  <section className="breadcrumb-osahan pt-5 pb-5 position-relative text-center">
    <h1 className="text-white">{title}</h1>
    {subTitle
      ? <h6 className="text-white-50">{subTitle}</h6>
      : ''}
  </section>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
};
PageTitle.defaultProps = {
  subTitle: '',
};

export default PageTitle;
