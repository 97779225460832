import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Row, Col, Container, Button, Tab, Image, Spinner, Form, Badge,
} from 'react-bootstrap';
import Icofont from 'react-icofont';
import deepmerge from 'deepmerge';
import { useSelector, useDispatch } from 'react-redux';
import CartDropDownItem from './cart/CartDropdownItem';
import settings from '../config/settings';
import ItemOptionGroup from './item/ItemOption';
import { updateCart } from '../redux/reducers/cartSlice';

const EditItem = () => {
  const { id, cartItemIndex } = useParams();
  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);
  const frontendSettings = useSelector((state) => state.UI.frontendSettings);
  const history = useHistory();
  const dispatch = useDispatch();

  const [currentItem, setCurrentItem] = useState({});
  const [invalidItemId, setInvalidItemId] = useState(false);
  const [itemOptions, setItemOptions] = useState({});
  const [price, setPrice] = useState(0);
  const [displayErrors, setDisplayErrors] = useState(false);
  const [mandatoryItemOptionGroupIDs, setMandatoryItemOptionGroupIDs] = useState([]);
  const [notes, setNotes] = useState('');
  const [quantity, setQuantity] = useState(1);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  // when the current item comes back, iterate through and make a list of all the mandatory item option groups
  useEffect(() => {
    const temp = mandatoryItemOptionGroupIDs;
    if (Object.keys(currentItem).length > 0) {
      if (currentItem.ItemOptionGroups) {
        Object.values(currentItem?.ItemOptionGroups)?.forEach((itemOptionGroup) => {
          if (itemOptionGroup?.mandatory) {
            temp.push(itemOptionGroup?.item_option_group_id);
          }
        });
      }
      setMandatoryItemOptionGroupIDs(temp);
    }
    return () => {
      setMandatoryItemOptionGroupIDs([]);
    };
  }, [currentItem]);

  const updatePrice = () => {
    const calcItemOptionPrice = Object.keys(itemOptions).length > 0
      ? Object.values(itemOptions).reduce((acc, cur) => {
        if (cur.price > 0 && cur.value > 0) {
          return acc + (cur.price * cur.value);
        }
        return acc;
      }, 0)
      : 0;

    const newPrice = currentItem.price ? currentItem.price + calcItemOptionPrice : 0;
    setPrice(newPrice);
  };

  async function fetchItemDetails(itemId) {
    await fetch(`${settings.API_URL}/private/items/getItem?item_id=${itemId}`, {
      headers: {
        Authorization: `Bearer ${user.userToken}`,
      },
    })
      .then((res) => res.json())
      .then((item) => {
        if (item.item) {
          setCurrentItem(item.item);
          setPrice(item.item.price);
        } else {
          setInvalidItemId(true);
        }
      })
      .then(() => {
        const cartItem = cart?.items?.[cartItemIndex]?.[id];
        if (!cartItem) {
          setInvalidItemId(true);
        } else {
          setQuantity(cartItem.quantity);
          setNotes(cartItem.item_notes);
          setItemOptions(cartItem.ItemOptions);
        }
      });
  }

  // On first load fetch item details
  useEffect(() => {
    fetchItemDetails(id);
  }, []);

  useEffect(() => {
    updatePrice();
  }, [itemOptions]);

  // Marty copy paste special
  Object.filter = (obj, predicate) => Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {});

  // Need to remove the itemOption object for this if the value is 0, rather than just deepmergeing. This is so that
  // there's no difference between an item that has been checked and then unchecked and one that hasn't been checked
  const setValue = (update) => {
    let temp = { ...itemOptions };
    if (update.group_type === 'DROPDOWN') {
      temp = {
        ...Object.filter(temp, (itemOption) => itemOption.group_id !== update.group_id),
        [update.group_id]: update,
      };
    } else {
      temp = deepmerge(temp, { [update.id]: update });
    }

    // Loop through itemOptions (temp object) and delete objects with 0 value
    // eslint-disable-next-line no-restricted-syntax
    for (const key in temp) {
      // eslint-disable-next-line no-continue, no-prototype-builtins
      if (!temp.hasOwnProperty(key)) continue;

      if (temp[key].value === 0) {
        delete temp[key];
      }
    }
    setItemOptions(temp);
  };

  const getValue = (itemOptionID) => itemOptions[itemOptionID];

  // Checks first to ensure all mandatory fields are selected, then will update the cart
  const handleUpdateCart = () => {
    let localReqFields = true;
    const comparisonKeys = Object.keys(itemOptions);
    let tempFlag = true;
    mandatoryItemOptionGroupIDs.forEach((key) => {
      if (!comparisonKeys.includes(key.toString())) {
        tempFlag = false;
      }
    });
    if (!tempFlag) {
      setDisplayErrors(true);
      localReqFields = false;
    } else {
      setDisplayErrors(false);
      localReqFields = true;
    }
    if (localReqFields) {
      dispatch(updateCart({
        itemIndex: cartItemIndex,
        item: {
          [currentItem.item_id]: {
            item_id: currentItem.item_id,
            quantity,
            ItemOptions: itemOptions,
            product_name: currentItem.product_name,
            price,
            item_notes: notes,
          },
        },
      }));
      history.push('/menu');
    } else {
      setDisplayErrors(true);
    }
  };

  // Display loading indicator and error page for unknown item
  if (invalidItemId) {
    return (
      <Container className="mt-4 text-center">
        <p className="h5"> Cannot find selected item.</p>
        <Button onClick={() => history.goBack()}>Back</Button>
      </Container>
    );
  }
  if (Object.keys(currentItem).length <= 1) {
    return (
      <Container className="text-center">
        <Spinner animation="border" role="status" style={{ marginTop: '40px' }}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <>
      <section className="restaurant-detailed-banner">
        <div className="text-center">
          <Image
            fluid
            className="cover"
            src="/img/item_background.jpg"
            class="img-responsive"
            style={{
              maxHeight: '600px',
              height: '100vh',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          />
        </div>
        <div className="restaurant-detailed-header">
          <Container style={{ marginBottom: '40px' }}>
            <Row className="d-flex align-items-end">
              <Col md={8}>
                <div className="restaurant-detailed-header-left">
                  <Image
                    fluid
                    className="mr-3 float-left"
                    alt={currentItem.product_name}
                    src={currentItem.image_url ? currentItem.image_url : 'img/food_icon.png'}
                  />
                  <h2 className="text-white">{currentItem.product_name}</h2>
                  <p className="text-white mb-1">
                    {currentItem.description}
                  </p>
                  <div>
                    {currentItem.allergen_peanut ? <Badge style={{ marginRight: '4px' }} variant="warning">Contains Peanut</Badge> : null}
                    {currentItem.allergen_treenut ? <Badge style={{ marginRight: '4px' }} variant="warning">Contains Tree Nut</Badge> : null}
                    {currentItem.allergen_milk ? <Badge style={{ marginRight: '4px' }} variant="warning">Contains Milk</Badge> : null}
                    {currentItem.allergen_eggs ? <Badge style={{ marginRight: '4px' }} variant="warning">Contains Egg</Badge> : null}
                    {currentItem.allergen_sesame ? <Badge style={{ marginRight: '4px' }} variant="warning">Contains Sesame</Badge> : null}
                    {currentItem.allergen_fish ? <Badge style={{ marginRight: '4px' }} variant="warning">Contains Fish</Badge> : null}
                    {currentItem.allergen_shellfish ? <Badge style={{ marginRight: '4px' }} variant="warning">Contains Shellfish</Badge> : null}
                    {currentItem.allergen_soy ? <Badge style={{ marginRight: '4px' }} variant="warning">Contains Soy</Badge> : null}
                    {currentItem.allergen_wheat ? <Badge style={{ marginRight: '4px' }} variant="warning">Contains Wheat</Badge> : null}
                    {currentItem.allergen_lupin ? <Badge style={{ marginRight: '4px' }} variant="warning">Contains Lupin</Badge> : null }
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="restaurant-detailed-header-right text-right" style={{ fontSize: '1.5em' }}>
                  $
                  {Number.parseFloat(currentItem.price).toFixed(2)}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Tab.Container defaultActiveKey="first">
        <section className="offer-dedicated-body pt-2 pb-2 mt-4 mb-4">
          <Container>
            <Row>
              <Col md={8}>
                <div className="offer-dedicated-body-left">
                  <Tab.Content className="h-100">
                    <Tab.Pane eventKey="first">
                      {currentItem.ItemOptionGroups?.map((itemOptionGroup) => (
                        <ItemOptionGroup
                          // eslint-disable-next-line prefer-template
                          key={itemOptionGroup.item_option_group_id + 'key'}
                          itemOptionGroup={itemOptionGroup}
                          setValue={setValue}
                          getValue={getValue}
                        />
                      ))}
                      {frontendSettings?.allow_changes_to_menu?.option_value === 'FALSE' ? (
                        null
                      ) : (
                        <Row>
                          <h5 className="mb-4 mt-3 col-md-12">
                            Notes
                          </h5>
                          <Col md={12}>
                            <div className="bg-white rounded border shadow-sm">
                              <div className="p-3 border-bottom">
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                  <Form.Control as="textarea" value={notes} onChange={(e) => setNotes(e.target.value)} />
                                </Form.Group>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Col>
              <Col md={4}>
                <div
                  className="bg-white rounded shadow-sm text-white mb-4 p-4 clearfix restaurant-detailed-earn-pts card-icon-overlap"
                >
                  <Row>
                    <Col
                      style={{
                        padding: '0px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                      }}
                      xs={3}
                    >
                      <h6 className="mb-1 text-white" style={{ marginBottom: '0px!important' }}>
                        Total: $
                        {(price * quantity).toFixed(2)}
                      </h6>
                    </Col>
                    <Col
                      xs={6}
                      style={{
                        display: 'inline-grid',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        variant="success"
                        onClick={() => {
                          handleUpdateCart();
                        }}
                      >
                        Update Cart
                      </Button>
                    </Col>
                    <Col
                      xs={3}
                      style={{
                        padding: '0px',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      <span className="count-number" style={{ margin: 'auto' }}>
                        <Button
                          variant="outline-secondary"
                          onClick={() => {
                            decreaseQuantity();
                          }}
                          className="btn-sm left dec"
                          disabled={quantity <= 1}
                        >
                          <Icofont icon="minus" />
                        </Button>
                        <input
                          className="count-number-input"
                          type="text"
                          value={quantity}
                          readOnly
                        />
                        <Button
                          variant="outline-secondary"
                          onClick={() => {
                            increaseQuantity();
                          }}
                          className="btn-sm right inc"
                        >
                          <Icofont icon="icofont-plus" />
                        </Button>
                      </span>
                    </Col>
                  </Row>
                  <p style={{ textAlign: 'center', alignContent: 'center', marginTop: '8px' }}>
                    {displayErrors ? 'Please select all required fields' : null}
                  </p>
                </div>
                <div className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item">
                  <h5 className="mb-1 text-white">
                    Your Order
                  </h5>
                  <p className="mb-4 text-white">
                    {cart.itemsInCart}
                    {' '}
                    Items
                  </p>
                  <div className="bg-white rounded shadow-sm mb-2">
                    {cart.items.map((item) => (
                      <CartDropDownItem
                        key={Object.keys(item)[0]}
                        item={item}
                      />
                    ))}
                  </div>
                  <div className="mb-2 bg-white rounded p-2 clearfix">
                    <Image fluid className="float-left" src="/img/wallet-icon.png" />
                    <h6 className="font-weight-bold text-right mb-2">
                      Subtotal:
                      <span className="text-danger">
                        {' '}
                        $
                        {cart.cartTotal.toFixed(2)}
                      </span>
                    </h6>
                    <p className="seven-color mb-1 text-right">Extra charges may apply</p>
                  </div>
                  <Link to="/checkout" className="btn btn-success btn-block btn-lg">
                    Checkout
                    <Icofont icon="long-arrow-right" />
                  </Link>
                  <div className="pt-2" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Tab.Container>
    </>
  );
};

export default EditItem;
EditItem.propTypes = {
};

EditItem.defaultProps = {
};
