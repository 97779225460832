/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';

const DropDownItem = ({
  orderDetail,
}) => (
  <div className="gold-members p-2 border-bottom">
    <div
      className="media"
    >
      <div className="mr-2"><Icofont icon="ui-press" className="text-success food-item" /></div>
      <div className="media-body">
        <p className="mt-1 mb-0 text-black">
          {orderDetail.quantity > 1 ? `${orderDetail.quantity}x ` : ''}
          {orderDetail.Item.product_name}
          <p className="text-gray mb-0 float-right ml-2">
            {orderDetail.quantity > 1 && orderDetail.price_at_order > 0
              ? `$${(orderDetail.price_at_order * orderDetail.quantity).toFixed(2)} ($${orderDetail.price_at_order}ea)`
              : `$${orderDetail.price_at_order.toFixed(2)}`}
          </p>
        </p>
        {Object.values(orderDetail.ItemOptionDetails).map((itemOptionDetail) => (
          <>
            <p style={{ marginTop: '0px', marginBottom: '0px' }} key={itemOptionDetail.ItemOption.product_name}>
              <small className="text-info" style={{ marginTop: '0px' }}>
                {itemOptionDetail.quantity > 1 ? `${itemOptionDetail.quantity}x ` : ''}
                {itemOptionDetail.ItemOption.product_name}
              </small>
              <p className="text-gray mb-0 float-right ml-2">
                {itemOptionDetail.quantity > 1 && itemOptionDetail.price_at_order > 0
                  ? `$${(itemOptionDetail.price_at_order * itemOptionDetail.quantity).toFixed(2)} ($${itemOptionDetail.price_at_order}ea)`
                  : `$${itemOptionDetail.price_at_order.toFixed(2)}`}
              </p>
            </p>
          </>
        ))}
        { Object.keys(orderDetail.item_notes).length > 0
          ? (
            <p style={{ marginTop: '0px', marginBottom: '0px' }}>
              <small className="text-info" style={{ marginTop: '0px' }}>
                Notes:
                {' '}
                {orderDetail.item_notes}
              </small>
            </p>
          ) : null}
      </div>
    </div>
  </div>
);

DropDownItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  orderDetail: PropTypes.object.isRequired,
  // icoIcon: PropTypes.string.isRequired,
  // title: PropTypes.string.isRequired,
  // iconClass: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
};

DropDownItem.defaultProps = {
};

export default DropDownItem;
