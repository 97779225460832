import { createSlice } from '@reduxjs/toolkit';

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    orders: [],
    invoice: {},
  },

  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    clearOrders: (state) => {
      state.orders = [];
    },
    setInvoice: (state, action) => {
      state.invoice = action.payload;
    },
    clearInvoice: (state) => {
      state.invoice = {};
    },
  },
});

export const {
  setOrders,
  clearOrders,
  setInvoice,
  clearInvoice,
} = orderSlice.actions;

export default orderSlice.reducer;
