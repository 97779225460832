import { addNewAlert } from '../../redux/reducers/AlertSlice';

const AlertHandler = (dispatch, type, group, subgroup, message, displayToUser) => {
  // SEND MESSAGE TO LOGGING SERVER
  // TODO: Send message to backend to forward to logging server.

  // SEND MESSAGE TO CONSOLE
  if (process.env.REACT_APP_DEBUG) {
    // eslint-disable-next-line no-console
    console.log(`${type}: [${group}|${subgroup}] ${message}`);
  }
  if (displayToUser) {
    // ADD ALERT TO SCREEN
    dispatch(addNewAlert({
      id: Math.random().toString(36).slice(-5),
      type,
      message,
    }));
  }

  return null;
};

export default AlertHandler;
