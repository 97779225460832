import React, { useState, useEffect } from 'react';
import {
  Badge, Button, Media,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';

const QuantityItemOption = ({
  itemClass, showBadge, badgeVariant, badgeText, setValue, itemOption, getValue,
}) => {
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    setQuantity(getValue(itemOption?.item_option_id)?.value || 0);
  }, []);

  const IncrementItem = () => {
    const quant = quantity + 1;
    setQuantity(quant);
    setValue({
      id: itemOption.item_option_id,
      value: quant,
      price: itemOption.price,
      product_name: itemOption.product_name,
      group_id: itemOption.item_option_group_id,
    });
  };

  const DecrementItem = () => {
    const quant = quantity - 1;
    setQuantity(quant);
    setValue({
      id: itemOption.item_option_id,
      value: quant,
      price: itemOption.price,
    });
  };

  return (
    <div className={`p-3 border-bottom ${itemClass}`}>
      {quantity === 0
        ? (
          <span className="float-right mt-2">
            <Button variant="outline-secondary" onClick={() => IncrementItem()} size="sm">ADD</Button>
          </span>
        )
        : (
          <span className="count-number float-right mt-2">
            <Button variant="outline-secondary" onClick={() => DecrementItem()} className="btn-sm left dec">
              {' '}
              <Icofont icon="minus" />
              {' '}
            </Button>
            <input className="count-number-input" type="text" value={quantity} readOnly />
            <Button variant="outline-secondary" onClick={() => IncrementItem()} className="btn-sm right inc">
              {' '}
              <Icofont icon="icofont-plus" />
              {' '}
            </Button>
          </span>
        )}
      <Media>
        <div className="mr-3"><Icofont icon="ui-press" className={`text-${badgeVariant} food-item`} /></div>
        <Media.Body>
          <h6 className="mb-1">
            {itemOption.product_name}
            {' '}
            {showBadge ? <Badge variant={badgeVariant}>{badgeText}</Badge> : ''}
          </h6>
          <p className="text-gray mb-0">
            $
            {itemOption.price}
          </p>
        </Media.Body>
      </Media>
    </div>
  );
};

QuantityItemOption.propTypes = {
  itemOption: {
    item_option_id: PropTypes.string,
    price: PropTypes.number,
    product_name: PropTypes.string,
  },
  itemClass: PropTypes.string,
  showBadge: PropTypes.bool,
  badgeVariant: PropTypes.string,
  badgeText: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
};
QuantityItemOption.defaultProps = {
  itemOption: {
    item_option_id: 0,
    price: 0,
    product_name: '',
  },
  itemClass: 'gold-members',
  showBadge: false,
  badgeText: '',
  badgeVariant: 'danger',
};

export default QuantityItemOption;
