import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row, Col, Container, Image, Table, Spinner,
} from 'react-bootstrap';
// import Icofont from 'react-icofont';
import { useDispatch, useSelector } from 'react-redux';
import HeadingValue from './common/HeadingValue';
import PageTitle from './common/PageTitle';
import { setInvoice, clearInvoice } from '../redux/reducers/orderSlice';
import settings from '../config/settings';
import formatDate from './common/FormatDate';

const Invoice = () => {
  // const history = useHistory();
  const orderID = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const invoice = useSelector((state) => state.order.invoice);

  useEffect(() => {
    // side effects
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user.userToken}` },
      body: JSON.stringify({ order_id: orderID.id }),
    };

    fetch(`${settings.API_URL}/private/order/getOrderDetails`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Unauthorized');
      })
      .then((data) => {
        dispatch(setInvoice(data.orderDetails));
      })
      .catch((error) => {
        console.log(error);
      });

    // cleanup
    return () => {
      dispatch(clearInvoice());
    };
  }, []);

  if (Object.keys(invoice).length > 0) {
    return (
      <>
        <PageTitle
          title="Invoice"
          subTitle={`Order #${invoice.order_id}`}
        />
        <section className="section">
          <Container>
            <Row>
              <Col md={8} className="mx-auto">
                <div className="p-5 osahan-invoice bg-white shadow-sm">
                  <Row className="mb-5 pb-3">
                    <Col md={8} xs={10}>
                      <h3 className="mt-0">
                        Thanks for choosing
                        {' '}
                        <strong className="text-secondary">{settings.restaurantName}</strong>
                        ,
                        {' '}
                        {user.userFirstName}
                        ! Here are your order details:
                      </h3>
                    </Col>
                    <Col md={4} xs={2} className="pl-0 text-right">
                      <p className="mb-4 mt-2" />
                      <Image alt="logo" src="/img/logo.png" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <HeadingValue
                        heading="Order No: "
                        value={`#${invoice.order_id}`}
                        className="text-black"
                      />
                      <HeadingValue
                        heading="Order placed at: "
                        value={invoice.createdAt ? formatDate(invoice.createdAt) : null}
                      />
                      <HeadingValue
                        heading="Order Status: "
                        value={invoice.status}
                        valueClassName="text-success"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col md={12}>
                      <HeadingValue
                        heading="Ordered from:"
                      />
                      <h6 className="mb-1 text-black"><strong>{settings.restaurantName}</strong></h6>
                      <HeadingValue
                        heading={settings.restaurantLocation}
                      />
                      <Table className="mt-3 mb-0">
                        <thead className="thead-light">
                          <tr>
                            <th className="text-black font-weight-bold" scope="col">Item Name</th>
                            <th className="text-right text-black font-weight-bold" scope="col">Quantity</th>
                            <th className="text-right text-black font-weight-bold" scope="col">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoice.OrderDetails.map((orderDetail) => (
                            <>
                              <tr>
                                <td>{orderDetail.Item.product_name}</td>
                                <td className="text-right">{orderDetail.quantity}</td>
                                <td className="text-right">
                                  {orderDetail.quantity > 1 && orderDetail.price_at_order > 0
                                    ? `$${(orderDetail.price_at_order * orderDetail.quantity).toFixed(2)} ($${orderDetail.price_at_order}ea)`
                                    : `$${orderDetail.price_at_order.toFixed(2)}`}
                                </td>
                              </tr>
                              {
                                Object.values(orderDetail.ItemOptionDetails).length > 0
                                  ? (
                                    [Object.values(orderDetail.ItemOptionDetails).map((itemOptionDetail) => (
                                      <tr>
                                        <td style={{ borderTop: 'none' }}>
                                          <small style={{ marginLeft: '12px' }}>
                                            {itemOptionDetail.ItemOption.product_name}
                                          </small>
                                        </td>
                                        <td className="text-right">{itemOptionDetail.quantity}</td>
                                        <td className="text-right">
                                          {itemOptionDetail.quantity > 1 && itemOptionDetail.price_at_order > 0
                                            ? `$${(itemOptionDetail.price_at_order * itemOptionDetail.quantity).toFixed(2)} ($${itemOptionDetail.price_at_order}ea)`
                                            : `$${itemOptionDetail.price_at_order.toFixed(2)}`}
                                        </td>
                                      </tr>
                                    ))]
                                  ) : null
                              }
                            </>
                          ))}
                          <tr>
                            <td className="text-right" colSpan="2">Surcharge:</td>
                            <td className="text-right">
                              {invoice.surcharge_type === 'AMOUNT' ? `$${invoice.surcharge_amount}` : null}
                              {invoice.surcharge_type === 'PERCENT' ? `${invoice.surcharge_percent}%` : null}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-right" colSpan="2">Merchant Fees:</td>
                            <td className="text-right">
                              $
                              {invoice.merchant_fees}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-right" colSpan="2">
                              <h6 className="text-success">Grand Total:</h6>
                            </td>
                            <td className="text-right">
                              <h6 className="text-success">
                                $
                                {invoice.total.toFixed(2)}
                              </h6>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  } return (
    <Container className="text-center">
      <Spinner animation="border" role="status" style={{ marginTop: '40px' }}>
        <span className="sr-only">Loading...</span>
      </Spinner>
    </Container>
  );
};
export default Invoice;
