import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container, Spinner,
} from 'react-bootstrap';
import { resetUserState } from '../redux/reducers/userSlice';

const Logout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.userToken) {
      dispatch(resetUserState());
      history.push('/login');
    }

    return () => {
      history.push('/login');
    };
  }, []);

  return (
    <Container className="text-center">
      <Spinner animation="border" role="status" style={{ marginTop: '40px' }}>
        <span className="sr-only">Logging you out...</span>
      </Spinner>
    </Container>
  );
};
export default Logout;
