import React from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import {
  Row, Col, Container, Form, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

const RegistrationStage1 = (props) => {
  const { registerUser, userAlreadyExists } = props;

  const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    mobileNumber: yup.string().matches(/\+?614\d{8}$|04\d{8}$/, { excludeEmptyString: true }),
    email: yup.string().email().required(),
    password: yup.string().required(),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null]).required(),
  });

  return (
    <div className="login d-flex align-items-center py-5">
      <Container>
        <Row>
          <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
            <h3 className="login-heading mb-4">Hey there!</h3>
            <Formik
              validationSchema={schema}
              onSubmit={registerUser}
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                mobileNumber: '',
                password: '',
                confirmPassword: '',
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                _isValid,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  {/* <div className="form-label-group"> */}
                  <Form.Label style={{ marginTop: '8px' }} htmlFor="firstName">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="firstName"
                    placeholder="First Name"
                    value={values.firstName}
                    onBlur={handleBlur}
                    isInvalid={touched.firstName && errors.firstName}
                    isValid={touched.firstName && !errors.firstName}
                    onChange={handleChange}
                  />
                  {/* </div> */}
                  {/* <div className="form-label-group"> */}
                  <Form.Label style={{ marginTop: '8px' }} htmlFor="lastName">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="lastName"
                    placeholder="Last Name"
                    value={values.lastName}
                    onBlur={handleBlur}
                    isInvalid={touched.lastName && errors.lastName}
                    isValid={touched.lastName && !errors.lastName}
                    onChange={handleChange}
                  />
                  {/* </div> */}
                  {/* <div className="form-label-group"> */}
                  <Form.Label style={{ marginTop: '8px' }} htmlFor="email">Email address</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    placeholder="Email Address"
                    value={values.email}
                    onBlur={handleBlur}
                    isInvalid={touched.email && errors.email}
                    isValid={touched.email && !errors.email}
                    onChange={handleChange}
                  />
                  {/* </div> */}
                  {/* <div className="form-label-group"> */}
                  <Form.Label style={{ marginTop: '8px' }} htmlFor="mobileNumber">Mobile Number</Form.Label>
                  <Form.Control
                    type="phone"
                    id="mobileNumber"
                    placeholder="Mobile Number"
                    value={values.mobileNumber}
                    onBlur={handleBlur}
                    isInvalid={touched.mobileNumber && errors.mobileNumber}
                    isValid={touched.mobileNumber && !errors.mobileNumber}
                    onChange={handleChange}
                  />
                  {/* </div> */}
                  {/* <div className="form-label-group"> */}
                  <Form.Label style={{ marginTop: '8px' }} htmlFor="password">Password</Form.Label>
                  <Form.Control
                    type="password"
                    id="password"
                    placeholder="Password"
                    value={values.password}
                    onBlur={handleBlur}
                    isInvalid={touched.password && errors.password}
                    isValid={touched.password && !errors.password}
                    onChange={handleChange}
                  />
                  {/* </div> */}
                  {/* <div className="form-label-group"> */}
                  <Form.Label style={{ marginTop: '8px' }} htmlFor="confirmPassword">Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                    isInvalid={touched.password && errors.confirmPassword}
                    isValid={touched.password && !errors.confirmPassword}
                    onChange={handleChange}
                  />
                  {/* </div> */}
                  <button
                    id="BUTTON_1"
                    style={{ marginTop: '16px' }}
                    className="btn btn-lg btn-block btn-login text-uppercase font-weight-bold mb-2"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Sign Up
                  </button>
                  <div className="text-center pt-3">
                    Already have an account?
                    {' '}
                    <Link className="font-weight-bold" to="/login">Sign In</Link>
                  </div>
                </Form>
              )}
            </Formik>
            {userAlreadyExists ? (
              <div style={{ marginTop: '8px', alignContent: 'center', textAlign: 'center' }}>
                <p>
                  A user with that email already exists. Navigating you to the login page...
                </p>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Navigating you to the login page...</span>
                </Spinner>
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
RegistrationStage1.propTypes = {
  registerUser: PropTypes.func.isRequired,
  userAlreadyExists: PropTypes.bool.isRequired,
};
export default RegistrationStage1;
