import React from 'react';
import PropTypes from 'prop-types';

const FontAwesome = ({
  icon,
  className,
}) => (
  <i className={`fa fa-${icon} ${className}`} />
);

FontAwesome.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};
FontAwesome.defaultProps = {
  className: '',
};

export default FontAwesome;
