import React from 'react';
import {
  Badge, Media, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';

const CheckboxItemOption = ({
  itemClass, showBadge, badgeVariant, badgeText, setValue, itemOption, getValue,
}) => {
  const ToggleValue = (e) => {
    setValue({
      id: itemOption.item_option_id,
      price: itemOption.price,
      product_name: itemOption.product_name,
      value: e.target.checked ? 1 : 0,
      group_id: itemOption.item_option_group_id,
    });
  };
  return (
    <div className={`p-3 border-bottom ${itemClass}`}>
      <span className="float-right">
        <Form.Check type="checkbox" className="mt-2" checked={getValue(itemOption?.item_option_id)?.value === 1} style={{ transform: 'scale(2)' }} onClick={ToggleValue} />
      </span>
      <Media>
        <div className="mr-3"><Icofont icon="ui-press" className={`text-${badgeVariant} food-item`} /></div>
        <Media.Body>
          <h6 className="mb-1">
            {itemOption.product_name}
            {' '}
            {showBadge ? <Badge variant={badgeVariant}>{badgeText}</Badge> : ''}
          </h6>
          <p className="text-gray mb-0">
            $
            {itemOption.price}
          </p>
        </Media.Body>
      </Media>
    </div>
  );
};
export default CheckboxItemOption;

CheckboxItemOption.defaultProps = {
  itemClass: 'gold-members',
  showBadge: false,
  badgeText: '',
  badgeVariant: 'danger',
};

CheckboxItemOption.propTypes = {
  itemClass: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  showBadge: PropTypes.bool,
  badgeVariant: PropTypes.string,
  badgeText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  itemOption: PropTypes.object.isRequired,
};
