import React from 'react';
import PropTypes from 'prop-types';

const SectionHeading = ({ alignClass, heading, subHeading }) => (
  <div className={`section-header ${alignClass}`}>
    <h2>{heading}</h2>
    {subHeading ? (
      <p>{subHeading}</p>
    ) : ''}
    <span className="line" />
  </div>
);

SectionHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  alignClass: PropTypes.string,
};
SectionHeading.defaultProps = {
  subHeading: '',
  alignClass: 'text-center',
};

export default SectionHeading;
