import { createSlice } from '@reduxjs/toolkit';

const getLocalStorageCart = () => {
  // Allows us to add c
  const localStorageCart = JSON.parse(localStorage.getItem('OAPPCART'));
  if (localStorageCart && localStorageCart.items && localStorageCart.cartTotal && localStorageCart.itemsInCart) {
    return {
      items: localStorageCart.items,
      cartTotal: localStorageCart.cartTotal,
      itemsInCart: localStorageCart.itemsInCart,

    };
  }
  return {
    items: [],
    cartTotal: 0,
    itemsInCart: 0,
  };
};

const initialState = getLocalStorageCart();

// https://stackoverflow.com/questions/1068834/object-comparison-in-javascript
// Jean Vincent's answer. Worth the read if you want to understand how it works
// eslint hates it though
function objectEquals(x, y) {
  let p;
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  // eslint-disable-next-line no-restricted-syntax
  for (p in x) {
    // eslint-disable-next-line no-continue, no-prototype-builtins
    if (!x.hasOwnProperty(p)) continue;
    // other properties were tested using x.constructor === y.constructor

    // eslint-disable-next-line no-prototype-builtins
    if (!y.hasOwnProperty(p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    // eslint-disable-next-line no-continue
    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof (x[p]) !== 'object') return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!objectEquals(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  // eslint-disable-next-line no-restricted-syntax, no-prototype-builtins
  for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
  // allows x[ p ] to be set to undefined

  return true;
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    // Adds item to cart, if item already exists and is identical (except quantity) then just increase the quantity
    addToCart: (state, action) => {
      let itemFoundFlag = false;
      // nothing to compare, just push to array
      if (state.items.length === 0) {
        itemFoundFlag = true;
        state.items.push(action.payload);
      } else {
        // search the array and find identical item
        for (let index = 0; index < state.items.length; index += 1) {
          if (!itemFoundFlag) {
            if ((Object.keys(state.items[index])[0]) === Object.keys(action.payload)[0]) {
              // if item is found, compare the ItemOptions, if they are equal then increase the quantity
              if (objectEquals(state.items[index][(Object.keys(state.items[index])[0])].ItemOptions,
                action.payload[Object.keys(action.payload)[0]].ItemOptions)
                // Also compare notes on the item
                && state.items[index][(Object.keys(state.items[index])[0])].item_notes
                === action.payload[Object.keys(action.payload)[0]].item_notes) {
                itemFoundFlag = true;
                state.items[index][(Object.keys(state.items[index])[0])].quantity += 1;
              }
            }
          }
        }
        // if the item array was looped through with no hits, then push action.payload
        if (!itemFoundFlag) {
          state.items.push(action.payload);
        }
      }
      let tempItemsTotal = 0;
      let tempTotal = 0;
      state.items.forEach((item) => {
        tempTotal += item[Object.keys(item)[0]].price * item[Object.keys(item)[0]].quantity;
        tempItemsTotal += item[Object.keys(item)[0]].quantity;
      });
      state.cartTotal = tempTotal;
      state.itemsInCart = tempItemsTotal;
      window.localStorage.setItem('OAPPCART', JSON.stringify({
        items: state.items,
        cartTotal: state.cartTotal,
        itemsInCart: state.itemsInCart,
      }));
    },
    // functionally the same as addItemToCart
    incrementItem: (state, action) => {
      let itemFoundFlag = false;
      // nothing to compare, just push to array
      if (state.items.length === 0) {
        itemFoundFlag = true;
        state.items.push(action.payload);
      } else {
        // search the array and find identical item
        for (let index = 0; index < state.items.length; index += 1) {
          if (!itemFoundFlag) {
            if ((Object.keys(state.items[index])[0]) === Object.keys(action.payload)[0]) {
              // if item is found, compare the ItemOptions, if they are equal then increase the quantity
              if (objectEquals(state.items[index][(Object.keys(state.items[index])[0])].ItemOptions,
                action.payload[Object.keys(action.payload)[0]].ItemOptions)
                // Also compare notes on the item
                && state.items[index][(Object.keys(state.items[index])[0])].item_notes
                === action.payload[Object.keys(action.payload)[0]].item_notes) {
                itemFoundFlag = true;
                state.items[index][(Object.keys(state.items[index])[0])].quantity += 1;
              }
            }
          }
        }
        // if the item array was looped through with no hits, then push action.payload
        if (!itemFoundFlag) {
          state.items.push(action.payload);
        }
      }
      let tempItemsTotal = 0;
      let tempTotal = 0;
      state.items.forEach((item) => {
        tempTotal += item[Object.keys(item)[0]].price * item[Object.keys(item)[0]].quantity;
        tempItemsTotal += item[Object.keys(item)[0]].quantity;
      });
      state.cartTotal = tempTotal;
      state.itemsInCart = tempItemsTotal;
      window.localStorage.setItem('OAPPCART', JSON.stringify({
        items: state.items,
        cartTotal: state.cartTotal,
        itemsInCart: state.itemsInCart,
      }));
    },
    // special case for if the item quantity is 1, then we will delete the item from the cart instead
    decrementItem: (state, action) => {
      let itemFoundFlag = false;
      // nothing to compare, just push to array
      if (state.items.length === 0) {
        itemFoundFlag = true;
        state.items.push(action.payload);
      } else {
        // search the array and find identical item
        for (let index = 0; index < state.items.length; index += 1) {
          if (!itemFoundFlag) {
            if ((Object.keys(state.items[index])[0]) === Object.keys(action.payload)[0]) {
              // if item is found, compare the ItemOptions, if they are equal then increase the quantity
              if (objectEquals(state.items[index][(Object.keys(state.items[index])[0])].ItemOptions,
                action.payload[Object.keys(action.payload)[0]].ItemOptions)
              // Also compare notes on the item
                && state.items[index][(Object.keys(state.items[index])[0])].item_notes
                === action.payload[Object.keys(action.payload)[0]].item_notes) {
                itemFoundFlag = true;
                if (state.items[index][(Object.keys(state.items[index])[0])].quantity > 1) {
                  state.items[index][(Object.keys(state.items[index])[0])].quantity -= 1;
                } else {
                  state.items.splice(index, 1);
                }
              }
            }
          }
        }
        // if the item array was looped through with no hits, then push action.payload
        if (!itemFoundFlag) {
          state.items.push(action.payload);
        }
      }
      let tempItemsTotal = 0;
      let tempTotal = 0;
      state.items.forEach((item) => {
        tempTotal += item[Object.keys(item)[0]].price * item[Object.keys(item)[0]].quantity;
        tempItemsTotal += item[Object.keys(item)[0]]?.quantity;
      });
      state.cartTotal = tempTotal;
      state.itemsInCart = tempItemsTotal;
      window.localStorage.setItem('OAPPCART', JSON.stringify({
        items: state.items,
        cartTotal: state.cartTotal,
        itemsInCart: state.itemsInCart,
      }));
    },
    clearCart: (state) => {
      state.items = [];
      state.cartTotal = 0;
      state.itemsInCart = 0;
      window.localStorage.removeItem('OAPPCART');
    },
    updateCart: (state, action) => {
      state.items.splice(action.payload.itemIndex, 1);
      // state.items.remove(action.payload.itemIndex);
      let itemFoundFlag = false;
      // nothing to compare, just push to array
      if (state.items.length === 0) {
        itemFoundFlag = true;
        state.items.push(action.payload.item);
      } else {
        // search the array and find identical item
        for (let index = 0; index < state.items.length; index += 1) {
          if (!itemFoundFlag) {
            if ((Object.keys(state.items[index])[0]) === Object.keys(action.payload.item)[0]) {
              // if item is found, compare the ItemOptions, if they are equal then increase the quantity
              if (objectEquals(state.items[index][(Object.keys(state.items[index])[0])].ItemOptions,
                action.payload.item[Object.keys(action.payload.item)[0]].ItemOptions)
                // Also compare notes on the item
                && state.items[index][(Object.keys(state.items[index])[0])].item_notes
                === action.payload.item[Object.keys(action.payload.item)[0]].item_notes) {
                itemFoundFlag = true;
                state.items[index][(Object.keys(state.items[index])[0])].quantity += 1;
              }
            }
          }
        }
        // if the item array was looped through with no hits, then push action.payload
        if (!itemFoundFlag) {
          state.items.push(action.payload.item);
        }
      }
      let tempItemsTotal = 0;
      let tempTotal = 0;
      state.items.forEach((item) => {
        tempTotal += item[Object.keys(item)[0]].price * item[Object.keys(item)[0]].quantity;
        tempItemsTotal += item[Object.keys(item)[0]].quantity;
      });
      state.cartTotal = tempTotal;
      state.itemsInCart = tempItemsTotal;
      window.localStorage.setItem('OAPPCART', JSON.stringify({
        items: state.items,
        cartTotal: state.cartTotal,
        itemsInCart: state.itemsInCart,
      }));
    },
  },
});

export const {
  addToCart,
  clearCart,
  incrementItem,
  decrementItem,
  updateCart,
} = cartSlice.actions;

export default cartSlice.reducer;
