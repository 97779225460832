/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { incrementItem, decrementItem } from '../../redux/reducers/cartSlice';
import { setIsNavExpanded } from '../../redux/reducers/UISlice';

const CartDropdownItem = ({
  item,
}) => {
  const dispatch = useDispatch();
  const itemIndex = Object.keys(item)[0];
  const history = useHistory();
  const cart = useSelector((state) => state.cart);
  // if there are item options then have no bottom margin to display sub items
  // let marginBottomValue = { marginBottom: '8px' };
  // if (Object.keys(ItemOptions).length > 0) {
  //   marginBottomValue = { marginBottom: '0px' };
  // }

  const IncrementItem = (i) => {
    dispatch(incrementItem(i));
  };

  const DecreaseItem = (i) => {
    dispatch(decrementItem(i));
  };

  return (
    <div className="gold-members p-2 border-bottom">
      <p className="text-gray mb-0 float-right ml-2" style={{ width: '30px' }}>
        $
        {(item[itemIndex].price * item[itemIndex].quantity).toFixed(2)}
      </p>
      <span className="count-number float-right">
        <Button
          variant="outline-secondary"
          onClick={() => DecreaseItem(item)}
          className="btn-sm left dec"
        >
          <Icofont icon="minus" />
        </Button>
        <input className="count-number-input" type="text" value={item[itemIndex].quantity} readOnly />
        <Button
          variant="outline-secondary"
          onClick={() => IncrementItem(item)}
          className="btn-sm right inc"
        >
          <Icofont icon="icofont-plus" />
        </Button>
      </span>
      <div
        className="media"
        onClick={() => {
          dispatch(setIsNavExpanded(false));
          history.push(`/edit/item/${item[itemIndex].item_id}/${cart.items.findIndex((obj) => obj === item)}`);
          history.go(0);
        }}
      >
        <div className="mr-2"><Icofont icon="ui-press" className="text-success food-item" /></div>
        <div className="media-body">
          <p className="mt-1 mb-0 text-black">{item[itemIndex].product_name}</p>
          {
            Object.keys(item[itemIndex].ItemOptions).length > 0
              ? Object.values(item[itemIndex].ItemOptions).map((itemOption) => (
                <p style={{ marginTop: '0px', marginBottom: '0px' }} key={itemOption.product_name}>
                  <small className="text-info" style={{ marginTop: '0px' }}>
                    {itemOption.product_name}
                  </small>
                </p>
              )) : null
          }
          { Object.keys(item[itemIndex].item_notes).length > 0
            ? (
              <p style={{ marginTop: '0px', marginBottom: '0px' }}>
                <small className="text-info" style={{ marginTop: '0px' }}>
                  Notes:
                  {' '}
                  {item[itemIndex].item_notes}
                </small>
              </p>
            ) : null}
        </div>
      </div>
    </div>
  );
};

CartDropdownItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  price: PropTypes.number,
  // icoIcon: PropTypes.string.isRequired,
  // title: PropTypes.string.isRequired,
  // iconClass: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  ItemOptions: PropTypes.object,
};

CartDropdownItem.defaultProps = {
  price: 0,
  ItemOptions: {},
};

export default CartDropdownItem;
