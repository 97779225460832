import { createSlice } from '@reduxjs/toolkit';

export const UISlice = createSlice({
  name: 'UI',
  initialState: {
    isNavExpanded: false,
    frontendSettings: {},
  },
  reducers: {
    setIsNavExpanded: (state, action) => {
      state.isNavExpanded = action.payload;
    },
    updateFrontendSettings: (state, action) => {
      state.frontendSettings = action.payload;
    },
  },
});

export const { setIsNavExpanded, updateFrontendSettings } = UISlice.actions;

export default UISlice.reducer;
