import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

const Footer = ({ copyrightText }) => (
  <>
    <footer className="pt-4 pb-4 text-center">
      <Container>
        <p className="mt-0 mb-0">{copyrightText}</p>
      </Container>
    </footer>
  </>
);

Footer.propTypes = {
  copyrightText: PropTypes.string,
};

Footer.defaultProps = {
  copyrightText: '© Copyright 2021 AnalytiCall. All Rights Reserved',
};

export default Footer;
