import React, { useEffect, useState } from 'react';
import {
  Row, Col, Container, ProgressBar, Alert,
} from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import RegistrationStage1 from './RegistrationStage1';
import RegistrationStage2 from './RegistrationStage2';
import RegistrationStage3 from './RegistrationStage3';
import settings from '../../config/settings';

const Register = () => {
  const [registrationDetails, updateRegistrationDetails] = useState({});
  const [registrationStage, updateRegistrationStage] = useState(33);
  const [accessToken, setAccessToken] = useState('');
  const [userAlreadyExists, setUserAlreadyExists] = useState(false);
  const [registrationAlert, setRegistrationAlert] = useState('');
  const [confirmationEmailAlert, setConfirmationAlert] = useState('');

  const history = useHistory();
  const resetForm = () => {
    updateRegistrationDetails({});
    updateRegistrationStage(33);
  };

  // when page loads, check for url query strings
  const queryString = new URLSearchParams(useLocation().search);

  // if a user is attempting to open this page with a user token, confirm the email with that token
  useEffect(() => {
    // If token is present in the query string, use this to confirm the email
    if (queryString.has('token')) {
      const token = queryString.get('token');
      // need to capture this access token for the requesting a phone confirmation code
      setAccessToken(token);
      fetch(`${settings.API_URL}/private/confirmEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((responseAsJSON) => {
              if (responseAsJSON.message === 'Thank you for confirming your email') {
                // Only need the following information to finish off the registration process
                updateRegistrationDetails({
                  email: responseAsJSON.user.email,
                  mobileNumber: responseAsJSON.user.phone_number,
                });
                // Email is confirmed, pass straight to the phone registration stage
                updateRegistrationStage(100);
              }
            });
          }
        })
        .catch((error) => console.log(error));
    }
  }, []);

  // checks if the user exists on form submit, if it does, display a warning on RegistrationStage1 and redirect to login
  useEffect(() => {
    if (userAlreadyExists) {
      setTimeout(() => { history.push('/login'); }, 2000);
    }
  }, [userAlreadyExists]);

  // Attempts to register the user to the backend
  const registerUser = (formData) => {
    // put the form data in useState
    updateRegistrationDetails(formData);

    // post registration details to backend
    fetch(`${settings.API_URL}/public/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        email: formData.email,
        phone_number: formData.mobileNumber,
        password: formData.password,
        password2: formData.confirmPassword,
        first_name: formData.firstName,
        last_name: formData.lastName,
      }),
    })
      .then((response) => {
        if (response.ok) {
          // go to next stage
          updateRegistrationStage(66);
        } return response.json();
      }).then((responseAsJSON) => {
        if (responseAsJSON.message === 'User already exists') {
          setUserAlreadyExists(true);
        } else {
          // show the error to the user, this is dismissable and only shows the last error if multiple error handlers
          // use this.
          setRegistrationAlert(responseAsJSON.message);
        }
      })
      .catch((error) => console.log(error));
  };

  // contextually renders each RegistrationStage component
  const getRegistrationForm = () => {
    switch (registrationStage) {
      case 33:
        return (<RegistrationStage1 registerUser={registerUser} userAlreadyExists={userAlreadyExists} />);
      case 66:
        return (
          <RegistrationStage2
            registrationDetails={registrationDetails}
            resetForm={resetForm}
            updateRegistrationStage={updateRegistrationStage}
            setAccessToken={setAccessToken}
            setConfirmationAlert={setConfirmationAlert}
          />
        );
      case 100:
        return (<RegistrationStage3 registrationDetails={registrationDetails} accessToken={accessToken} />);
      default:
        return null;
    }
  };

  const getRegistrationLabel = () => {
    switch (registrationStage) {
      case 33:
        return 'Step 1';
      case 66:
        return 'Step 2';
      case 100:
        return 'Step 3';
      default:
        return null;
    }
  };

  return (
    <Container fluid className="bg-white">
      <Row>
        <Col md={4} lg={6} className="d-none d-md-flex bg-image" />
        <Col md={8} lg={6}>
          {registrationAlert !== '' && registrationAlert ? (
            <Alert key="registrationAlert" variant="danger" onClose={() => setRegistrationAlert('')} dismissible>
              {registrationAlert}
            </Alert>
          ) : null}
          {confirmationEmailAlert !== '' ? (
            <Alert key="confirmationEmailAlert" variant="info" onClose={() => setConfirmationAlert('')} dismissible>
              {confirmationEmailAlert}
            </Alert>
          ) : null}
          <ProgressBar
            className="mt-2"
            now={registrationStage}
            label={`${getRegistrationLabel()}`}
          />
          {getRegistrationForm()}
        </Col>
      </Row>
    </Container>
  );
};
export default Register;
