import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, Link, useHistory } from 'react-router-dom';
import {
  Navbar, Nav, Container, NavDropdown, Image,
} from 'react-bootstrap';
import Icofont from 'react-icofont';
import DropDownTitle from './DropDownTitle';
import CartDropdownHeader from '../cart/CartDropdownHeader';
import CartDropdownItem from '../cart/CartDropdownItem';
import { setIsNavExpanded } from '../../redux/reducers/UISlice';
import settings from '../../config/settings';
import { resetUserState } from '../../redux/reducers/userSlice';
import { clearCart } from '../../redux/reducers/cartSlice';

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isNavExpanded = useSelector((state) => state.UI.isNavExpanded);
  const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
  // const userEmail = useSelector((state) => state.UI.userEmail);
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const frontendSettings = useSelector((state) => state.UI.frontendSettings);

  const closeMenu = () => {
    dispatch(setIsNavExpanded(false));
  };

  // Clears the cart if an admin happens to turn off ordering whilst someone has orders in their cart
  useEffect(() => {
    if (frontendSettings?.ordering_enabled?.option_value === 'FALSE') {
      dispatch(clearCart());
    }
  }, [frontendSettings]);

  const headerNode = useRef(null);

  useEffect(() => {
    const checkUserToken = () => {
      const token = user.userToken;
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      };

      fetch(`${settings.API_URL}/public/validate`, requestOptions)
        .then((response) => {
          if (!response.ok) { // either HTTP 200 or HTTP 401
            dispatch(resetUserState());
            history.push('/login');
          }
        }).catch(() => {
          dispatch(resetUserState());
          history.push('/login');
        });
    };
    checkUserToken();
    const interval = setInterval(() => checkUserToken(), 600000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div ref={(node) => { headerNode.current = node; }}>
      <Navbar
        onToggle={() => dispatch(setIsNavExpanded(!isNavExpanded))}
        expanded={isNavExpanded}
        color="light"
        expand="lg"
        className="navbar-light osahan-nav shadow-sm"
      >
        <Container>
          <Navbar.Brand to="/">
            <Image
              style={{ cursor: 'pointer' }}
              onClick={() => { history.push('/'); }}
              src="/img/logo.png"
              alt="logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse id="navbarNavDropdown">
            <Nav activeKey={0} className="ml-auto" onSelect={closeMenu}>
              <Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="/">
                Home
                {' '}
                <span className="sr-only">(current)</span>
              </Nav.Link>
              <Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="/menu">
                Menu
                {' '}
                <span className="sr-only">Menu</span>
              </Nav.Link>
              <NavDropdown
                alignRight
                title={(
                  <DropDownTitle
                    className="d-inline-block"
                    image="/img/user.png"
                    imageAlt="user"
                    imageClass="nav-osahan-pic rounded-pill"
                    title="My Account"
                  />
                )}
              >
                {isUserLoggedIn
                  ? (
                    <>
                      <NavDropdown.Item eventKey={4.1} as={NavLink} activeclassname="active" to="/myaccount/orders">
                        <Icofont icon="food-cart" />
                        {' '}
                        Account Information
                      </NavDropdown.Item>
                      <NavDropdown.Item eventKey={4.1} as={NavLink} activeclassname="active" to="/logout">
                        <Icofont icon="food-cart" />
                        {' '}
                        Logout
                      </NavDropdown.Item>
                    </>
                  )
                  : (
                    <>
                      (
                      <NavDropdown.Item eventKey={4.1} as={NavLink} activeclassname="active" to="/login">
                        <Icofont icon="food-cart" />
                        {' '}
                        Login
                      </NavDropdown.Item>
                      <NavDropdown.Item eventKey={4.1} as={NavLink} activeclassname="active" to="/register">
                        <Icofont icon="food-cart" />
                        {' '}
                        Register
                      </NavDropdown.Item>
                      )
                      {' '}
                    </>
                  ) }
              </NavDropdown>
              <NavDropdown
                activeclassname="active"
                alignRight
                className="dropdown-cart"
                title={(
                  <DropDownTitle
                    className="d-inline-block"
                    faIcon="shopping-basket"
                    iconClass="mr-1"
                    title="Cart"
                    badgeClass="ml-1"
                    badgeVariant="success"
                    badgeValue={cart.itemsInCart}
                  />
                )}
              >
                <div className="dropdown-cart-top shadow-sm">
                  <CartDropdownHeader
                    className="dropdown-cart-top-header p-4"
                    title="Cart"
                    subTitle=""
                    NavLinkUrl="#"
                    NavLinkText="View Full Menu"
                  />
                  <div className="dropdown-cart-top-body border-top p-4">
                    {cart?.items?.length > 0
                      ? (
                        <>
                          {cart.items.map((item) => {
                            const itemIndex = Object.keys(item)[0];
                            return (
                              <CartDropdownItem
                                key={itemIndex}
                                icoIcon="ui-press"
                                iconClass="text-success food-item"
                                item={item}
                                title={`${item[itemIndex]?.product_name}${item[itemIndex]?.quantity > 1 ? ` x ${item[itemIndex].quantity}` : ''}`}
                                price={item[itemIndex]?.price * item[itemIndex]?.quantity}
                                itemOptions={item[itemIndex]?.ItemOptions}
                              />
                            );
                          })}
                        </>
                      )
                      : (
                        <div style={{ color: 'white', textAlign: 'center' }}>
                          Check out the menu to add items to your cart!
                        </div>
                      )}
                  </div>
                  {cart.items.length > 0 ? (
                    <div className="dropdown-cart-top-footer border-top p-4">
                      <p className="mb-0 font-weight-bold text-secondary">
                        Sub Total
                        <span className="float-right text-dark">
                          $
                          {cart.cartTotal.toFixed(2)}
                        </span>
                      </p>
                      <small className="text-info">Extra charges may apply</small>
                    </div>
                  ) : null}
                  {cart.items.length > 0 ? (
                    <div className="dropdown-cart-top-footer border-top p-2">
                      <NavDropdown.Item eventKey={5.1} as={Link} className="btn btn-success btn-block py-3 text-white text-center dropdown-item" to="/checkout">Checkout</NavDropdown.Item>
                    </div>
                  ) : (
                    <div className="dropdown-cart-top-footer border-top p-2">
                      <NavDropdown.Item eventKey={5.1} as={Link} className="btn btn-success btn-block py-3 text-white text-center dropdown-item" to="/menu">Menu</NavDropdown.Item>
                    </div>
                  ) }
                </div>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
