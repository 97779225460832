import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Row, Col, Container, Form, Button, Alert, Image,
} from 'react-bootstrap';
// import FontAwesome from './common/FontAwesome';
import settings from '../config/settings';
import {
  setIsUserLoggedIn,
  setUserEmail,
  setUserToken,
  setUserID,
  setUserFirstName,
  setUserLastName,
  setEmailConfirmed,
  setPhoneConfirmed,
  setUserPhoneNumber,
  setPermissionLevel,
} from '../redux/reducers/userSlice';

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [tempDisableLogin, setTempDisableLogin] = useState(false);

  const handleLogin = () => {
    if (email !== '' && password !== '') {
      setTempDisableLogin(true);
      setTimeout(() => setTempDisableLogin(false), 2000);
      fetch(`${settings.API_URL}/public/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      })
        .then((response) => {
          response.json().then((data) => {
            if (data.message === 'Email or Password Wrong') throw new Error(data.message);
            if (data.token) {
              window.localStorage.setItem('OAPPTOKEN', JSON.stringify(data));
              dispatch(setIsUserLoggedIn(true));
              dispatch(setUserEmail(email));
              dispatch(setUserToken(data.token));
              dispatch(setUserID(data.user.user_id));
              dispatch(setUserFirstName(data.user.first_name));
              dispatch(setUserLastName(data.user.last_name));
              dispatch(setEmailConfirmed(data.user.email_confirmed));
              dispatch(setPhoneConfirmed(data.user.phone_confirmed));
              dispatch(setUserPhoneNumber(data.user.phone_number));
              dispatch(setPermissionLevel(data.user.permission_level));
              setEmail('');
              setPassword('');
              history.push('/');
            }
          }).catch((err) => {
            setLoginError(err.message);
            setTimeout(() => setLoginError(''), 2000);
          });
        })
        .catch((_err) => {
        });
    } else {
      // TODO: error
    }
  };

  // add in option to login with enter, don't know why this isn't a default in Form
  // may be missing something
  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      handleLogin();
    }
  };

  return (
    <Container fluid className="bg-white">
      <Row>
        <Col
          md={4}
          lg={6}
          className="d-none d-md-flex bg-image"
          style={{ padding: '0px 0px 0px 0px' }}
        >
          <Image
            fluid
            className="float-right"
            src="/img/Image (4).jpeg"
            style={{ objectFit: 'cover', width: 'calc(50vw)', height: '100vh' }}
          />
        </Col>
        <Col md={8} lg={6}>
          <div className="login d-flex align-items-center py-5">
            <Container>
              <Row>
                <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
                  <h3 className="login-heading mb-4">Welcome back!</h3>
                  <Form>
                    {/* <div className="form-label-group"> */}
                    <div>
                      <Form.Label
                        htmlFor="inputEmail"
                      >
                        Email Address
                      </Form.Label>
                      <Form.Control
                        style={{
                          // position: 'absolute',
                          top: '0',
                          left: '0',
                          display: 'block',
                          width: '100%',
                          marginBottom: ' 0',
                          lineHeight: '1.5',
                          color: '#495057',
                          cursor: 'text',
                          border: ' 1px solid transparent',
                          borderRadius: ' .25rem',
                          transition: ' all .1s ease-in-out',
                        }}
                        type="email"
                        id="inputEmail"
                        placeholder="Email address"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {/* </div> */}
                      {/* <div className="form-label-group"> */}
                      <Form.Label htmlFor="inputPassword">Password</Form.Label>
                      <Form.Control type="password" id="inputPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)} onKeyPress={(e) => handleKeyPress(e)} />
                    </div>
                    {/* </div> */}
                    <Form.Check
                      className="mb-3"
                      custom
                      type="checkbox"
                      id="custom-checkbox"
                      label="Remember password"
                    />
                    <Button
                      className="btn btn-lg btn-block btn-login text-uppercase font-weight-bold mb-2"
                      disabled={tempDisableLogin}
                      onClick={() => {
                        handleLogin();
                      }}
                    >
                      Sign in
                    </Button>
                    {loginError !== '' ? (
                      <Alert variant="dark" role="alert">
                        {loginError}
                      </Alert>
                    ) : null}
                    <div className="text-center pt-3">
                      Don’t have an account?
                      {' '}
                      <Link className="font-weight-bold" to="/register">Sign Up</Link>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default Login;
