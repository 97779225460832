import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Row, Col, Container, Form, Tab, Nav,
  Image,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Icofont from 'react-icofont';
import {
  SquarePaymentForm,
  CreditCardNumberInput,
  CreditCardExpirationDateInput,
  CreditCardCVVInput,
  CreditCardSubmitButton,
} from 'react-square-payment-form';
import CartDropdownItem from './cart/CartDropdownItem';
import settings from '../config/settings';
import { clearCart } from '../redux/reducers/cartSlice';
import 'react-square-payment-form/lib/default.css';
import AlertHandler from './AlertHandler/AlertHandler';
import FontAwesome from './common/FontAwesome';

async function createOrder(token, userID) {
  const promise = new Promise(((resolve, reject) => {
    fetch(`${settings.API_URL}/private/order/createOrder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order: {
          user_id: userID,
        },
      }),
    }).then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  }));
  return promise;
}

async function getCurrentWaitTime(token, orderID) {
  const promise = new Promise(((resolve, reject) => {
    fetch(`${settings.API_URL}/private/order/getCurrentWaitTime`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order_id: orderID,
      }),
    }).then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  }));
  return promise;
}

async function addItems(token, userID, items, orderID) {
  const promise = new Promise(((resolve, reject) => {
    // fetch the URL to process the payment
    fetch(`${settings.API_URL}/private/order/addItem`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order: {
          user_id: userID,
          orderItems: items.reduce((curr, acc) => ([
            ...curr,
            { ...acc[Object.keys(acc)[0]] },
          ]), []),
          order_id: orderID,
        },
      }),
    }).then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  }));
  return promise;
}

async function processPayment(token, nonce, buyerVerificationToken, orderID, price) {
  const promise = new Promise(((resolve, reject) => {
    // fetch the URL to process the payment
    fetch(`${settings.API_URL}/private/payments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        nonce, token: buyerVerificationToken, order_id: orderID, price,
      }),
    }).then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  }));
  return promise;
}

const Checkout = () => {
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const frontendSettings = useSelector((state) => state.UI.frontendSettings);
  const dispatch = useDispatch();
  const history = useHistory();
  const [cartTotal, setCartTotal] = useState(0);
  const [waitTime, setCurrentWaitTime] = useState(0);
  const [orderID, setOrderID] = useState(0);
  const [surchargeString, setSurchargeString] = useState('');
  const [merchantFeesString, setMerchantFeesString] = useState('');

  useEffect(() => {
    if (frontendSettings.default_surcharge_type) {
      let total = parseFloat(cart.cartTotal);

      if (frontendSettings.default_surcharge_type.option_value === 'AMOUNT') {
        total += parseFloat(frontendSettings.default_surcharge_amount.option_value);
        setSurchargeString(`Surcharge: $${Math.round((parseFloat(frontendSettings.default_surcharge_amount.option_value) + Number.EPSILON) * 100) / 100}`);
      }
      if (frontendSettings.default_surcharge_type.option_value === 'PERCENT') {
        setSurchargeString(`Surcharge: $${Math.round(((total * (parseFloat(frontendSettings.default_surcharge_percent.option_value) / 100)) + Number.EPSILON) * 100) / 100}`);
        total *= (1 + (parseFloat(frontendSettings.default_surcharge_percent.option_value) / 100));
      }
      total += parseFloat(frontendSettings.default_merchant_fees.option_value);
      setMerchantFeesString(`Merchant Fees: $${Math.round((parseFloat(frontendSettings.default_merchant_fees.option_value) + Number.EPSILON) * 100) / 100}`);

      setCartTotal(Math.round((total + Number.EPSILON) * 100) / 100);
    } else if (frontendSettings.default_merchant_fees) {
      let total = parseFloat(cart.cartTotal);
      total += parseFloat(frontendSettings.default_merchant_fees.option_value);
      setCartTotal(Math.round((total + Number.EPSILON) * 100) / 100);
      setMerchantFeesString(`Merchant Fees: $${Math.round((parseFloat(frontendSettings.default_merchant_fees.option_value) + Number.EPSILON) * 100) / 100}`);
    } else {
      const total = parseFloat(cart.cartTotal);
      setCartTotal(Math.round((total + Number.EPSILON) * 100) / 100);
    }

    return () => {
      setCartTotal(0);
    };
  }, [cart, frontendSettings]);

  // Create the order when the page loads and store the order ID
  useEffect(() => {
    createOrder(user.userToken, user.userID)
      .then((orderResponse) => {
        setOrderID(orderResponse.order.order_id);
      })
      .catch((error) => { console.log(error); });

    return () => {
      setOrderID(0);
    };
  }, []);

  // function which handles nonce response received, takes the errors and maps them to the alert handlers
  // otherwise fetches payment endpoint with user token, must also pass in the amount and the order ID
  const cardNonceResponseReceived = async (errors, nonce, cardData, buyerVerificationToken) => {
    if (errors) {
      errors.map((error) => AlertHandler(error));
    } else {
      // add items to the order
      await addItems(user.userToken, user.userID, cart.items, orderID)
        .catch((error) => { console.log(error); });

      // complete the transaction for the order
      await processPayment(user.userToken, nonce, buyerVerificationToken, orderID, cartTotal)
        .then((response) => {
          if (response?.message === 'Payment successfully received') {
            // clear cart and point to invoice
            dispatch(clearCart());
            history.push(`/myaccount/orders/invoice/${orderID}`);
          } else {
            // error page
            history.push('/oops');
          }
        })
        .catch((error) => { AlertHandler(error); });
    }
  };

  useEffect(() => {
    if (orderID > 0) {
      getCurrentWaitTime(user.userToken, orderID).then((waitTimeResponse) => {
        console.log(waitTimeResponse);
        setCurrentWaitTime(waitTimeResponse.currentWaitTime);
      });
    }

    return () => {
      setCurrentWaitTime(0);
    };
  }, [orderID]);

  return (
    <section className="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
      <Container>
        <Row>
          <Col md={8}>
            <div className="offer-dedicated-body-left">
              <div className="bg-white rounded shadow-sm p-4 osahan-payment">
                <h4 className="mb-1">Choose payment method</h4>
                <h6 className="mb-3 text-black-50">Credit/Debit Cards</h6>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col sm={4} className="pr-0">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Link eventKey="first">
                          <Icofont icon="credit-card" />
                          {' '}
                          Credit/Debit Cards
                        </Nav.Link>
                        {frontendSettings?.pay_at_counter_enabled?.option_value !== 'FALSE' ? (
                          <Nav.Link eventKey="fifth">
                            <Icofont icon="money" />
                            Pay at Counter
                          </Nav.Link>
                        ) : null}
                      </Nav>
                    </Col>
                    <Col sm={8} className="pl-0">
                      <Tab.Content className="h-100">
                        <Tab.Pane eventKey="first">
                          <h6 className="mb-3 mt-0 mb-3">Card</h6>
                          <p>
                            We accept
                            <span className="osahan-card">
                              <Icofont icon="visa-alt" />
                              {' '}
                              <Icofont icon="mastercard-alt" />
                              {' '}
                              <Icofont icon="american-express-alt" />
                            </span>
                          </p>
                          <SquarePaymentForm
                            // sandbox
                            applicationId="sq0idp-YHa30-F_axa9SKUsdEYO4w"
                            locationId="L248RDB98T4N4"
                            cardNonceResponseReceived={cardNonceResponseReceived}
                          >
                            <fieldset className="sq-fieldset">
                              <CreditCardNumberInput />
                              <div className="sq-form-third">
                                <CreditCardExpirationDateInput />
                              </div>
                              <div className="sq-form-third float-right">
                                <CreditCardCVVInput />
                              </div>
                            </fieldset>
                            {cart.itemsInCart > 0 ? (
                              <CreditCardSubmitButton>
                                Pay $
                                {cartTotal}
                              </CreditCardSubmitButton>
                            ) : (
                              <Form.Group className="col-md-12 mb-0">
                                <Link onClick={() => { }} to="/menu" className="btn btn-secondary btn-block btn-lg">
                                  No items in cart
                                  {' '}
                                  <Icofont icon="icofont-cart" />
                                </Link>
                              </Form.Group>
                            )}
                          </SquarePaymentForm>
                          {waitTime > 0 ? (
                            <p style={{ textAlign: 'center', marginTop: '8px' }}>
                              Approximate wait time is
                              {' '}
                              {new Date(waitTime * 1000).toISOString().substr(11, 8)}
                            </p>
                          ) : null}
                        </Tab.Pane>
                        <Tab.Pane eventKey="fifth">
                          <h6 className="mb-3 mt-0 mb-3">Cash</h6>
                          <p>Please keep exact change handy to help us serve you better</p>
                          <hr />
                          <Form>
                            <Link to="/thanks" className="btn btn-success btn-block btn-lg">
                              PAY $
                              {
                                cartTotal.toFixed(2)
                              }
                              <Icofont icon="long-arrow-right" />
                            </Link>
                          </Form>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item">
              <h5 className="mb-1 text-white">
                Your Order
              </h5>
              <p className="mb-4 text-white">
                {cart.itemsInCart}
                {' '}
                Items
              </p>
              <div className="bg-white rounded shadow-sm mb-2">
                {cart.items.map((item) => (
                  <CartDropdownItem
                    key={`${Object.keys(item)[0]}checkOutDropDownItem`}
                    item={item}
                  />
                ))}
              </div>
              <div className="mb-2 bg-white rounded p-2 clearfix">
                <Image fluid className="float-left" src="/img/wallet-icon.png" />
                <h6 className="font-weight-bold text-right mb-2">
                  Subtotal:
                  <span className="text-danger">
                    {' '}
                    $
                    {cartTotal.toFixed(2)}
                  </span>
                </h6>
                <p className="seven-color mb-1 text-right">
                  {surchargeString !== '' ? surchargeString : null}
                </p>
                <p className="seven-color mb-1 text-right">
                  {merchantFeesString !== '' ? merchantFeesString : null}
                </p>
              </div>
              <div style={{ textAlign: 'center', alignContent: 'center' }}>
                <Link
                  style={{ marginTop: '9px', backgroundColor: '#822BFE' }}
                  to="menu"
                  className="btn btn-lg"
                >
                  Order More
                  {' '}
                  <FontAwesome icon="chevron-circle-left" />
                </Link>
              </div>
              <div className="pt-2" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Checkout;
